import { RootState } from '@/redux';
import { ApiV1DashBenchmarkResponse } from '@/types/apiv1/responses/dashboards/api-v1-benchmark-responses';
import { DashBenchmarkTableData } from '@/types/dashboards/benchmark';
import { apiV1DashboardService } from '@/utils/api';
import { formatDateApi } from '@/utils/dates';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';

interface DashBenchmarkSliceState {
  hotels: {
    tableData: DashBenchmarkTableData[];
    loading: boolean;
  };
  channels: {
    tableData: DashBenchmarkTableData[];
    loading: boolean;
  };
  segments: {
    tableData: DashBenchmarkTableData[];
    loading: boolean;
  };
}

const initialState: DashBenchmarkSliceState = {
  hotels: {
    tableData: [],
    loading: false,
  },
  channels: {
    tableData: [],
    loading: false,
  },
  segments: {
    tableData: [],
    loading: false,
  },
};

const dashBenchmarkSlice = createSlice({
  name: 'dashBenchmark',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashBenchmarkHotels.pending, (state) => {
        state.hotels = { ...initialState.hotels, loading: true };
      })
      .addCase(
        fetchDashBenchmarkHotels.fulfilled,
        (state, action: PayloadAction<ApiV1DashBenchmarkResponse>) => {
          state.hotels.loading = false;
          state.hotels.tableData = action.payload.tableData;
        },
      )
      .addCase(fetchDashBenchmarkHotels.rejected, (state, action) => {
        state.hotels.loading = false;
      })
      .addCase(fetchDashBenchmarkChannels.pending, (state) => {
        state.channels = { ...initialState.channels, loading: true };
      })
      .addCase(
        fetchDashBenchmarkChannels.fulfilled,
        (state, action: PayloadAction<ApiV1DashBenchmarkResponse>) => {
          state.channels.loading = false;
          state.channels.tableData = action.payload.tableData;
        },
      )
      .addCase(fetchDashBenchmarkChannels.rejected, (state, action) => {
        state.channels.loading = false;
      })
      .addCase(fetchDashBenchmarkSegments.pending, (state) => {
        state.segments = { ...initialState.segments, loading: true };
      })
      .addCase(
        fetchDashBenchmarkSegments.fulfilled,
        (state, action: PayloadAction<ApiV1DashBenchmarkResponse>) => {
          state.segments.loading = false;
          state.segments.tableData = action.payload.tableData;
        },
      )
      .addCase(fetchDashBenchmarkSegments.rejected, (state, action) => {
        state.segments.loading = false;
      });
  },
});

export const fetchDashBenchmarkHotels = createAsyncThunk<
  ApiV1DashBenchmarkResponse,
  void,
  { state: RootState }
>('benchmark/fetchDashBenchmarkHotels', async (_, { rejectWithValue, getState }) => {
  try {
    const { commonFilters, benchmarkFilters } = getState().filters;
    const { startDateBenchmark, endDateBenchmark } = benchmarkFilters;

    const { index, startDate, endDate, showGoals, reservationStatus } = commonFilters;

    const params: any = {
      startDate: formatDateApi(startDate),
      endDate: formatDateApi(endDate),
      startDateBenchmark: formatDateApi(startDateBenchmark),
      endDateBenchmark: formatDateApi(endDateBenchmark),
      index,
      showGoals,
      status: reservationStatus,
    };

    const response = await apiV1DashboardService.get<ApiV1DashBenchmarkResponse>(
      `/benchmark/hotels?${stringify(params, { arrayFormat: 'bracket' })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashBenchmarkChannels = createAsyncThunk<
  ApiV1DashBenchmarkResponse,
  void,
  { state: RootState }
>('benchmark/fetchDashBenchmarkChannels', async (_, { rejectWithValue, getState }) => {
  try {
    const { commonFilters, benchmarkFilters } = getState().filters;
    const { startDateBenchmark, endDateBenchmark } = benchmarkFilters;

    const { index, startDate, endDate, showGoals, channels, reservationStatus } = commonFilters;

    const params: any = {
      startDate: formatDateApi(startDate),
      endDate: formatDateApi(endDate),
      startDateBenchmark: formatDateApi(startDateBenchmark),
      endDateBenchmark: formatDateApi(endDateBenchmark),
      index,
      showGoals,
      channels: channels.filter((x) => !x.isGroup).map((x) => x._id),
      channelGroups: channels.filter((x) => x.isGroup).map((x) => x._id),
      status: reservationStatus,
    };

    const response = await apiV1DashboardService.get<ApiV1DashBenchmarkResponse>(
      `/benchmark/channels?${stringify(params, { arrayFormat: 'bracket' })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashBenchmarkSegments = createAsyncThunk<
  ApiV1DashBenchmarkResponse,
  void,
  { state: RootState }
>('benchmark/fetchDashBenchmarkSegments', async (_, { rejectWithValue, getState }) => {
  try {
    const { commonFilters, benchmarkFilters } = getState().filters;
    const { startDateBenchmark, endDateBenchmark } = benchmarkFilters;

    const { index, startDate, endDate, showGoals, segments, reservationStatus } = commonFilters;

    const params: any = {
      startDate: formatDateApi(startDate),
      endDate: formatDateApi(endDate),
      startDateBenchmark: formatDateApi(startDateBenchmark),
      endDateBenchmark: formatDateApi(endDateBenchmark),
      index,
      showGoals,
      segments: segments.filter((x) => !x.isGroup).map((x) => x._id),
      segmentGroups: segments.filter((x) => x.isGroup).map((x) => x._id),
      status: reservationStatus,
    };

    const response = await apiV1DashboardService.get<ApiV1DashBenchmarkResponse>(
      `/benchmark/segments?${stringify(params, { arrayFormat: 'bracket' })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export default dashBenchmarkSlice.reducer;
