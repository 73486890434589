import { GenericIdName } from '@/types/models/generic-id-name';

export function chunkData<T>(array: T[], chunkSize = 10): T[][] {
  let i: number, j: number;
  const newArray: T[][] = [];
  for (i = 0, j = array.length; i < j; i += chunkSize) {
    newArray.push(array.slice(i, i + chunkSize));
  }

  return newArray;
}

export function sortDropDowns<T extends GenericIdName>(options: T[], value: T[]) {
  // Display the selected first for better UX.
  return [...options].sort((a, b) => {
    let ai = value.findIndex((x) => x._id === a._id);
    ai = ai === -1 ? value.length + options.findIndex((x) => x._id === a._id) : ai;
    let bi = value.indexOf(b);
    bi = bi === -1 ? value.length + options.findIndex((x) => x._id === b._id) : bi;
    return ai - bi;
  });
}
