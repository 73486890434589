import { GenericIdName } from '@/types/models/generic-id-name';
import { useFormContext } from 'react-hook-form';
import SelectStaticMultiple, { SelectStaticMultipleProps } from '../select/SelectStaticMultiple';

interface Props<T extends GenericIdName>
  extends Omit<SelectStaticMultipleProps<T>, 'values' | 'onChangeValues'> {
  name: string;
}

export default function RHFSelectStaticMultiple<T extends GenericIdName>({
  name,
  ...props
}: Props<T>) {
  const { watch, setValue, formState, clearErrors } = useFormContext();

  const values = watch(name) || [];
  const selectedValues = props.options.filter((option) => values.includes(option._id));
  const error: string | undefined = formState.errors[name]?.message;

  const onChangeValues = (values: T[]) => {
    setValue(
      name,
      values.map((x) => x._id),
      { shouldDirty: true }
    );
    clearErrors(name);
  };

  return (
    <SelectStaticMultiple<T>
      {...props}
      onChangeValues={onChangeValues}
      error={error}
      values={selectedValues}
    />
  );
}
