import RHFSelectStatic from '@/components/forms/hook-form/RHFSelectStatic';
import RHFSelectStaticMultiple from '@/components/forms/hook-form/RHFSelectStaticMultiple';
import RHFTextField from '@/components/forms/hook-form/RHFTextField';
import { useAppSelector } from '@/redux';
import { FluctuationConfigOperation } from '@/types/enums/rms/fluctuation-config-operation.enum';
import { FluctuationType, allFluctuationTypes } from '@/types/enums/rms/fluctuation-type.enum';
import { daysOfWeek } from '@/utils/dates';
import { formatCurrency, formatPercentage } from '@/utils/number';
import { getRmsFieldNameHumanReadable } from '@/utils/rms/fluctuation';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FluctuationConfigFormValuesProps } from '.';

export default function FluctuationConfigModalStep2() {
  const { selectedDates } = useAppSelector((state) => state.rms.fluctuationConfigOptions);
  const { watch } = useFormContext<FluctuationConfigFormValuesProps>();
  const values = watch();

  return (
    <Grid container spacing={4} sx={{ mb: 5 }}>
      {values.operation === FluctuationConfigOperation.CUSTOM_PRICE ? (
        <>
          <Grid item xs={12} md={5}>
            <RHFTextField
              size="small"
              name="additionalPrice"
              prefix={values.additionalPriceType === FluctuationType.FIXED ? 'R$' : ''}
              suffix={values.additionalPriceType === FluctuationType.PERCENTAGE ? '%' : ''}
              type="number"
              label={
                values.additionalFieldRef === 'absolute'
                  ? 'Valor Final (absoluto)'
                  : values.additionalPriceType === FluctuationType.PERCENTAGE
                  ? 'Porcentagem Adicional'
                  : 'Valor adicional'
              }
              autoFocus
              helperText={
                values.additionalFieldRef === 'absolute'
                  ? `O valor final será ${formatCurrency(values.additionalPrice)}`
                  : `O valor final será ${getRmsFieldNameHumanReadable(
                      values.additionalFieldRef,
                    )} ${values.additionalPrice > 0 ? '+' : ''}${
                      values.additionalPriceType === FluctuationType.PERCENTAGE
                        ? formatPercentage(values.additionalPrice)
                        : formatCurrency(values.additionalPrice)
                    }
                    `
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <RHFSelectStatic
              disabled={values.additionalFieldRef === 'absolute'}
              size="small"
              name="additionalPriceType"
              label="Tipo"
              options={allFluctuationTypes}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <RHFSelectStatic
              size="small"
              name="additionalFieldRef"
              label="Aplicar em"
              options={['customPrice', 'appliedPrice', 'suggestedPrice'].map((x) => ({
                _id: x,
                name: getRmsFieldNameHumanReadable(x),
              }))}
            />
          </Grid>
        </>
      ) : null}
      <Grid item xs={12} md={12}>
        <RHFSelectStaticMultiple
          disabled={selectedDates.length <= 1}
          size="small"
          label="Dias da semana"
          name="weekDays"
          helperText="Selecione apenas os dias da semana onde essa alteração deverá ser aplicada."
          options={daysOfWeek}
        />
      </Grid>
    </Grid>
  );
}
