import { RootState } from '@/redux';
import { ApiV1GriResponse } from '@/types/apiv1/responses/rms/gri/api-v1-gri-response';
import { GriData } from '@/types/rms/gri/gri';
import { apiV1DashboardService } from '@/utils/api';
import { formatDateApi } from '@/utils/dates';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';

interface GriSliceState {
  loading: boolean;
  data: GriData[];
  emissionDates: string[];
}

const initialState: GriSliceState = {
  loading: false,
  data: [],
  emissionDates: [],
};

const griSlice = createSlice({
  name: 'gri',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGri.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGri.fulfilled, (state, action: PayloadAction<ApiV1GriResponse>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.emissionDates = action.payload.emissionDates;
      })
      .addCase(fetchGri.rejected, (state, action) => {
        state.loading = false;
        state.data = initialState.data;
        state.emissionDates = initialState.emissionDates;
      });
  },
});

export const fetchGri = createAsyncThunk<ApiV1GriResponse, void, { state: RootState }>(
  'rms/gri/fetchGri',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { griFilters } = getState().filters;
      const { startDateEmission, startDateBooking, endDateBooking } = griFilters;

      const params: any = {
        startDateEmission: formatDateApi(startDateEmission),
        startDateBooking: formatDateApi(startDateBooking),
        endDateBooking: formatDateApi(endDateBooking),
      };

      const response = await apiV1DashboardService.get(
        `/gri?${stringify(params, { arrayFormat: 'bracket' })}`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export default griSlice.reducer;
