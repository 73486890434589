import { Avatar, Box, BoxProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <Avatar src="/logo/logo_single.svg" alt="N" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
