import RHFSelectStatic from '@/components/forms/hook-form/RHFSelectStatic';
import { allFluctuationConfigOperations } from '@/types/enums/rms/fluctuation-config-operation.enum';
import { Grid } from '@mui/material';

export default function FluctuationConfigModalStep1() {
  return (
    <Grid container spacing={1} sx={{ mb: 5 }} alignItems="center" justifyContent="center">
      <Grid item xs={12} md={12}>
        <RHFSelectStatic
          size="small"
          name="operation"
          label="Escolha o tipo de operação a ser efetuada"
          options={allFluctuationConfigOperations}
        />
      </Grid>
    </Grid>
  );
}
