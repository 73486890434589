import { MaintenanceIllustration } from '@/assets';
import Page from '@/components/Page';
import { Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

interface Props {
  title?: string;
}
export default function MaintenancePage({ title = 'Página em construção' }: Props) {
  return (
    <Page title="Manutenção">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            {title}
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Estamos trabalhando para que esta página esteja disponível.
          </Typography>
          <MaintenanceIllustration sx={{ my: 10, height: 240 }} />
          <Button variant="contained" size="large" component={RouterLink} to="/">
            Página inicial
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
