import { Button, Grid } from '@mui/material';

interface Props {
  activeStep: number;
  onChangeStep: (step: number) => void;
}

export default function StepperNavigator({ activeStep, onChangeStep }: Props) {
  return (
    <Grid container justifyContent="space-between" sx={{ my: 2 }}>
      <Grid item xs={2} textAlign="center">
        {activeStep > 0 ? (
          <Button color="secondary" variant="outlined" onClick={() => onChangeStep(activeStep - 1)}>
            Anterior
          </Button>
        ) : null}
      </Grid>
      <Grid item xs={2} textAlign="center">
        {activeStep < 2 ? (
          <Button color="secondary" variant="outlined" onClick={() => onChangeStep(activeStep + 1)}>
            Próximo
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
}
