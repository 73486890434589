import { DashIndex } from '../enums/dashboards/dash-index.enum';
import { SortedTableData } from '../general/sorted-table-data';

export enum DashPickUpType {
  HOTELS = 'hotels',
  CHANNELS = 'channels',
  SEGMENTS = 'segments',
}

export type DashPickUpMenu = {
  type: DashPickUpType;
  label: string;
  enabledIndexes: DashIndex[];
  icon: string;
};

export type DashPickUpTableCol = {
  _id: string;
  label: string;
};

export interface DashPickUpTableChildRow {
  [key: string]: string | number; // Dynamic fields: hotelName, "2022-01-01" (dateOfIndexes ), updatedAt, channelName
}

export interface DashPickUpTableParentRow {
  childRows: DashPickUpTableChildRow[];
  date: string;
  total: number;
  [key: string]: string | number | DashPickUpTableChildRow[]; // Dynamic fields: date, "2022-01-01" (dateOfIndexes), total
}

export type PickUpSortedTableConfig = Record<string, SortedTableData>;

export type DashPickUpTableData = {
  parentRows: DashPickUpTableParentRow[];
  parentCols: DashPickUpTableCol[];
  childCols: DashPickUpTableCol[];
};
