import Iconify from '@/components/Iconify';
import { useNotification } from '@/hooks/useNotification';
import { useAppDispatch, useAppSelector } from '@/redux';
import { updateUserSession } from '@/redux/slices/authSlice';
import { ApiV1UpdateUserSessionRequest } from '@/types/apiv1/requests/api-v1-update-user-session-request';
import {
  UserSessionMode,
  getIconByUserSessionModel,
  getSessionQuantityText,
  getUserSessionModeHumaRead,
} from '@/types/enums/user-session-mode.enum';
import { GenericIdName } from '@/types/models/generic-id-name';
import { Hotel } from '@/types/models/hotel';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  AutocompleteCloseReason,
  Button,
  Tooltip,
  createFilterOptions,
} from '@mui/material';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { SxProps, styled, useTheme } from '@mui/material/styles';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FilterInput from './FilterInput';
import HeaderButton from './HeaderButton';
import PopperComponent from './PopperComponent';

const onlyOneHotelPaths = [
  // Caso existam mais páginas onde se aplica apenas um hotel adicionar aqui:
  '/dashboards/concurrency',
  '/rms/*',
];

const filterOptions = createFilterOptions<GenericIdName>({
  matchFrom: 'any',
  stringify: (option) => option.name + (option as any).slug + (option as any).city?.name,
});

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  minWidth: 250,
  width: 'auto',
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

export default memo(function UserSessionMenu() {
  const { pathname } = useLocation();
  const theme = useTheme();
  const { showSuccess, showWarning, showInfo } = useNotification();
  const dispatch = useAppDispatch();
  const [touched, setTouched] = useState(false);
  const [mode, setMode] = useState(UserSessionMode.HOTEL);
  const [hotels, setHotels] = useState<GenericIdName[]>([]);
  const [regionals, setRegionals] = useState<GenericIdName[]>([]);
  const [cities, setCities] = useState<GenericIdName[]>([]);
  const [wallets, setWallets] = useState<GenericIdName[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { userSession, submitingUserSession, userSessionDropdowns } = useAppSelector(
    (state) => state.auth,
  );
  const { user } = userSession;

  const isOnlyOneHotelPage = useMemo(() => {
    const basePath = pathname.split('/')[1];
    return onlyOneHotelPaths.includes(pathname) || onlyOneHotelPaths.includes(`/${basePath}/*`);
  }, [pathname]);

  useEffect(() => {
    const updateSessionToOneHotel = async () => {
      try {
        const params: ApiV1UpdateUserSessionRequest = {
          mode: UserSessionMode.HOTEL,
          hotels: [userSession.hotels[0]._id],
          regionals: [],
          cities: [],
          wallets: [],
        };
        const res = await dispatch(updateUserSession(params));
        if (!res.type.includes('rejected')) {
          showInfo('Contexto alterado automaticamente para o modo Hotel (apenas um hotel).');
        }
      } catch {
        //
      }
    };
    if (
      isOnlyOneHotelPage &&
      (userSession.hotels.length > 1 || userSession.mode !== UserSessionMode.HOTEL)
    ) {
      updateSessionToOneHotel();
    }
  }, [dispatch, isOnlyOneHotelPage, showInfo, userSession.hotels, userSession.mode]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const resetValues = useCallback(() => {
    setTouched(false);
    setMode(userSession.mode);
    setHotels(userSession.hotels);
    setRegionals(userSession.regionals);
    setCities(userSession.cities);
    setWallets(userSession.wallets);
  }, [userSession]);

  const handleClose = useCallback(() => {
    // setValue(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  }, [anchorEl]);

  const open = Boolean(anchorEl);
  const id = open ? 'label' : undefined;

  useEffect(() => {
    resetValues();
  }, [open, resetValues]);

  const onSubmit = async () => {
    if (isOnlyOneHotelPage && hotels.length > 1) {
      showWarning(
        'Esta página não permite alterar o contexto para mais de um hotel. Selecione apenas um hotel.',
        5e3,
      );
      return;
    }
    const params: ApiV1UpdateUserSessionRequest = {
      mode,
      hotels: hotels.map((x) => x._id),
      regionals: regionals.map((x) => x._id),
      cities: cities.map((x) => x._id),
      wallets: wallets.map((x) => x._id),
    };
    const res = await dispatch(updateUserSession(params));
    if (!res.type.includes('rejected')) {
      handleClose();
      showSuccess('Sessão alterada com sucesso!', 1e3);
    }
  };

  const modes = isOnlyOneHotelPage
    ? [UserSessionMode.HOTEL]
    : user.isAdmin
    ? Object.keys(UserSessionMode)
    : [
        UserSessionMode.HOTEL,
        UserSessionMode.REGIONAL,
        UserSessionMode.CITY,
        UserSessionMode.WALLET,
      ];

  const value =
    mode === UserSessionMode.HOTEL || isOnlyOneHotelPage
      ? hotels
      : mode === UserSessionMode.REGIONAL
      ? regionals
      : mode === UserSessionMode.CITY
      ? cities
      : wallets;

  const options =
    mode === UserSessionMode.HOTEL || isOnlyOneHotelPage
      ? userSessionDropdowns.hotels
      : mode === UserSessionMode.REGIONAL
      ? userSessionDropdowns.regionals
      : mode === UserSessionMode.CITY
      ? userSessionDropdowns.cities
      : userSessionDropdowns.wallets;

  const onChange = (newValue: GenericIdName[]) => {
    setTouched(true);
    if (mode === UserSessionMode.HOTEL) {
      setHotels(newValue);
    } else if (mode === UserSessionMode.REGIONAL) {
      setRegionals(newValue);
    } else if (mode === UserSessionMode.CITY) {
      setCities(newValue);
    } else {
      setWallets(newValue);
    }
  };

  const onSelectOnlyOne = async (event: React.MouseEvent<HTMLElement>, newValue: GenericIdName) => {
    event.preventDefault();
    event.stopPropagation();
    setTouched(false);
    const params: ApiV1UpdateUserSessionRequest = {
      mode: UserSessionMode.HOTEL,
      hotels: [],
      regionals: [],
      cities: [],
      wallets: [],
    };

    if (mode === UserSessionMode.HOTEL) {
      setHotels([newValue]);
      params.hotels = [newValue._id];
    } else if (mode === UserSessionMode.REGIONAL) {
      setRegionals([newValue]);
      params.mode = UserSessionMode.REGIONAL;
      params.regionals = [newValue._id];
    } else if (mode === UserSessionMode.CITY) {
      setCities([newValue]);
      params.mode = UserSessionMode.CITY;
      params.cities = [newValue._id];
    } else {
      setWallets([newValue]);
      params.mode = UserSessionMode.WALLET;
      params.wallets = [newValue._id];
    }

    const res = await dispatch(updateUserSession(params));
    if (!res.type.includes('rejected')) {
      showInfo(`Sessão alterada para ${newValue.name}!`);
      handleClose();
    }
  };

  const onSelectAll = async () => {
    setTouched(true);
    const params: ApiV1UpdateUserSessionRequest = {
      mode: userSession.mode,
      hotels: [],
      regionals: [],
      cities: [],
      wallets: [],
    };
    if (mode === UserSessionMode.HOTEL) {
      if (hotels.length === userSessionDropdowns.hotels.length) {
        setHotels(userSession.hotels);
      } else {
        setHotels(userSessionDropdowns.hotels);
        params.hotels = userSessionDropdowns.hotels.map((hotel) => hotel._id);
      }
    } else if (mode === UserSessionMode.REGIONAL) {
      if (regionals.length === userSessionDropdowns.regionals.length) {
        setRegionals(userSession.regionals);
      } else {
        setRegionals(userSessionDropdowns.regionals);
        params.regionals = userSessionDropdowns.regionals.map((regional) => regional._id);
      }
    } else if (mode === UserSessionMode.CITY) {
      if (cities.length === userSessionDropdowns.cities.length) {
        setCities(userSession.cities);
      } else {
        setCities(userSessionDropdowns.cities);
        params.cities = userSessionDropdowns.cities.map((city) => city._id);
      }
    } else {
      if (wallets.length === userSessionDropdowns.wallets.length) {
        setWallets(userSession.wallets);
      } else {
        setWallets(userSessionDropdowns.wallets);
        params.wallets = userSessionDropdowns.wallets.map((wallet) => wallet._id);
      }
    }
  };

  const allCount = useMemo(() => {
    switch (mode) {
      case UserSessionMode.REGIONAL:
        return userSessionDropdowns.regionals.length;
      case UserSessionMode.CITY:
        return userSessionDropdowns.cities.length;
      case UserSessionMode.WALLET:
        return userSessionDropdowns.wallets.length;
      case UserSessionMode.HOTEL:
      case UserSessionMode.ALL:
      default:
        return userSessionDropdowns.hotels.length;
    }
  }, [
    mode,
    userSessionDropdowns.cities.length,
    userSessionDropdowns.hotels.length,
    userSessionDropdowns.regionals.length,
    userSessionDropdowns.wallets.length,
  ]);

  const allIsSelected = useMemo(() => {
    if (mode === UserSessionMode.HOTEL) return hotels.length === userSessionDropdowns.hotels.length;
    if (mode === UserSessionMode.REGIONAL)
      return regionals.length === userSessionDropdowns.regionals.length;
    if (mode === UserSessionMode.CITY) return cities.length === userSessionDropdowns.cities.length;
    if (mode === UserSessionMode.WALLET)
      return wallets.length === userSessionDropdowns.wallets.length;
    return false;
  }, [
    mode,
    hotels.length,
    regionals.length,
    cities.length,
    wallets.length,
    userSessionDropdowns.cities.length,
    userSessionDropdowns.hotels.length,
    userSessionDropdowns.regionals.length,
    userSessionDropdowns.wallets.length,
  ]);

  const getTooltipHotelList = () => {
    const hotelList = userSession.hotels;
    const hasMoreThanFour = hotelList.length > 4;
    if (!hasMoreThanFour) return hotelList.map((hotel) => <p key={hotel._id}>{hotel.name}</p>);
    const firstThreeHotels = hotelList.slice(0, 3);
    const list = firstThreeHotels.map((hotel) => <p key={hotel._id}>{hotel.name}</p>);
    list.push(<p key={'moreHotels'}>{`e mais ${hotelList.length - 3} Hotéis...`}</p>);
    return list;
  };

  return (
    <>
      <Box sx={{ width: 'auto', fontSize: 23 }}>
        <HeaderButton disableRipple aria-describedby={id} onClick={handleOpen}>
          <Iconify sx={{ mr: 1 }} icon={getIconByUserSessionModel(userSession.mode)} />
          <Tooltip arrow title={getTooltipHotelList()}>
            <span style={{ textDecoration: 'underline' }}>
              Modo: {getUserSessionModeHumaRead(userSession.mode)} (
              {getSessionQuantityText(userSession)})
            </span>
          </Tooltip>
        </HeaderButton>
      </Box>
      <StyledPopper
        sx={{ minWidth: 400 }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
                padding: '8px 10px',
                fontWeight: 600,
              }}
            >
              <Box sx={{ flexGrow: 1 }}>Contexto da aplicação</Box>
              <LoadingButton
                variant="contained"
                disabled={!touched}
                color="primary"
                size="small"
                onClick={onSubmit}
                loading={submitingUserSession}
              >
                Aplicar
              </LoadingButton>
            </Box>

            <Box
              sx={{
                display: 'flex',
                borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
                padding: '8px 10px',
                fontWeight: 600,
              }}
            >
              {modes.map((x) => {
                const style: SxProps = {
                  cursor: 'pointer',
                  marginRight: '16px',
                  color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
                };
                if (mode === x) {
                  style.textDecoration = 'underline';
                  style.color = '#0366d6';
                }
                return (
                  <Box
                    sx={style}
                    onClick={() => {
                      setTouched(true);
                      setMode(x as UserSessionMode);
                    }}
                    key={x}
                  >
                    {getUserSessionModeHumaRead(x as UserSessionMode)}
                  </Box>
                );
              })}
            </Box>

            {mode !== UserSessionMode.ALL && (
              <>
                <Autocomplete
                  open
                  filterOptions={filterOptions}
                  multiple
                  onClose={(event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
                    if (reason === 'escape') {
                      handleClose();
                    }
                  }}
                  PopperComponent={PopperComponent}
                  value={value}
                  onChange={(event, newValue, reason) => {
                    if (
                      event.type === 'keydown' &&
                      (event as React.KeyboardEvent).key === 'Backspace' &&
                      reason === 'removeOption'
                    ) {
                      return;
                    }
                    onChange(newValue);
                  }}
                  disableCloseOnSelect
                  renderTags={() => null}
                  noOptionsText="Nenhum resultado"
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      {selected && (
                        <Box
                          component={() => (
                            <Iconify fontSize={15} sx={{ mr: 1 }} icon="mdi:check" />
                          )}
                        />
                      )}
                      {(mode === UserSessionMode.HOTEL ||
                        mode === UserSessionMode.REGIONAL ||
                        mode === UserSessionMode.WALLET) && (
                        <Box
                          component="span"
                          sx={{
                            width: 14,
                            height: 14,
                            flexShrink: 0,
                            borderRadius: '3px',
                            mr: 1,
                            mt: '2px',
                          }}
                          style={{ backgroundColor: (option as Partial<any>).primaryColor }}
                        />
                      )}
                      <Box
                        sx={{
                          flexGrow: 1,
                          '& span': {
                            color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
                          },
                        }}
                      >
                        {option.name}
                        {mode === UserSessionMode.HOTEL && (
                          <>
                            <br />
                            <span>
                              {(option as any).slug} ({(option as Partial<Hotel>).city?.name})
                            </span>
                          </>
                        )}
                      </Box>
                      <Button
                        variant="text"
                        size="small"
                        sx={{ flexGrow: 1, maxWidth: 80 }}
                        onClick={(e) => onSelectOnlyOne(e, option)}
                      >
                        Somente
                      </Button>
                    </li>
                  )}
                  options={options as GenericIdName[]}
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <FilterInput
                      ref={params.InputProps.ref}
                      inputProps={params.inputProps}
                      autoFocus
                      placeholder="Filtrar"
                    />
                  )}
                />
                <span
                  style={{
                    float: 'right',
                    margin: 5,
                    marginBottom: 0,
                    paddingBottom: '10px',
                    paddingRight: '10px',
                    cursor: 'pointer',
                    color: '#0366d6',
                    fontSize: '12px',
                    fontWeight: 900,
                    textDecoration: 'underline',
                  }}
                  onClick={onSelectAll}
                >
                  {allIsSelected ? `Desmarcar tudo (${allCount})` : `Selecionar tudo (${allCount})`}
                </span>
              </>
            )}
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
});
