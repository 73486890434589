import { defaultTableProps } from '@/config/table-config';
import { ConcurrentHotelAvailability, ConcurrentHotelRoom } from '@/types/dashboards/concurrency';
import { apiV1ConcurrencyService } from '@/utils/api';
import { formatCurrency } from '@/utils/number';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';

type GridType = ConcurrentHotelRoom;

interface Props {
  url: string;
  onClose: VoidFunction;
}

export function RoomsViewer({ onClose, url }: Props) {
  const [data, setData] = useState<ConcurrentHotelRoom[]>([]);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);

  const columns = useMemo<GridColDef<GridType>[]>(
    () => [
      {
        field: 'name',
        headerName: 'Nome do quarto',
        headerAlign: 'left',
        flex: 1,
        type: 'string',
        valueGetter: ({ row }) => row.name,
      },
      {
        field: 'price',
        headerName: 'Valor',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        type: 'string',
        renderCell: (params) => (
          <Typography variant="subtitle1">{formatCurrency(params.value)}</Typography>
        ),
      },
      {
        field: 'occupancy',
        headerName: 'Ocupação',
        align: 'center',
        type: 'string',
        valueGetter: ({ row }) => row.occupancy,
      },
    ],
    [],
  );

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const { data } = await apiV1ConcurrencyService.get<ConcurrentHotelAvailability>(url);
        setData(data.rooms);
      } catch {
        setHasError(true);
      } finally {
        setLoading(false);
      }
    };

    if (!!url) {
      getData();
    } else {
      setHasError(false);
      setData([]);
    }
  }, [url]);

  return (
    <Dialog fullWidth maxWidth="lg" open={!!url} onClose={() => onClose()}>
      <DialogTitle sx={{ mb: 2 }}>Quartos disponíveis</DialogTitle>
      <Divider />
      <DialogContent>
        {!!hasError && <DialogContentText>Erro ao abrir conteúdo</DialogContentText>}
        <Box
          noValidate
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DataGrid<GridType>
            {...defaultTableProps}
            paginationMode="client"
            sortingMode="client"
            pageSize={10}
            rows={data}
            loading={loading}
            columns={columns}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}
