import { userHasPermission } from '@/components/RoleGuard';
import { useSession } from '@/hooks/auth/useSession';
import { Stack } from '@mui/material';
import { memo, useMemo } from 'react';
import { NavSectionProps } from '../type';
import { NavListRoot } from './NavList';

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

function NavSectionHorizontal({ navConfig }: NavSectionProps) {
  const { user } = useSession();
  const filteredNavConfig: typeof navConfig = useMemo(() => {
    const filtered: typeof navConfig = [];
    navConfig.forEach((x) => {
      const { items, ...rest } = x;
      const filteredItems = items.filter(
        ({ permission }) => permission === undefined || userHasPermission(permission, user)
      );

      filteredItems.forEach((x) => {
        if (!!x.children) {
          x.children = x.children.filter(
            ({ permission }) => permission === undefined || userHasPermission(permission, user)
          );
        }
      });

      filtered.push({
        ...rest,
        items: filteredItems.filter((x: any) => x.children === undefined || x.children?.length > 0),
      });
    });

    return filtered.filter((x) => x.items.length > 0);
  }, [navConfig, user]);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{ bgcolor: 'background.neutral', borderRadius: 1, px: 0.5 }}
    >
      <Stack direction="row" sx={{ ...hideScrollbar, py: 1 }}>
        {filteredNavConfig.map((group) => (
          <Stack key={group.subheader} direction="row" flexShrink={1}>
            {group.items.map((list) => (
              <NavListRoot key={list.title + list.path} list={list} />
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
