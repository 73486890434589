import Iconify from '@/components/Iconify';

interface Props {
  occupancy: number;
}

export default function RenderOccupancy({ occupancy }: Props) {
  // Render occupancy quantity icons
  return (
    <>
      {Array.from({ length: occupancy }, (_, i) => (
        <Iconify key={i} icon="mdi:user" />
      ))}
    </>
  );
}
