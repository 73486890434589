import { PICK_UP_MENUS } from '@/config/dashboards/pick-up-old';
import { DashPickUpOldFilters } from '@/types/dashboards/dash-filters';
import { DashPickUpType } from '@/types/dashboards/pick-up-old';
import { DashIndex } from '@/types/enums/dashboards/dash-index.enum';
import { formatDateApi } from '../dates';
import { formatPercentage } from '../number';
import { getValueHumanReadByIndex } from './general';

export function getPickUpTableCellValue(value: number, index: DashIndex): string {
  return getValueHumanReadByIndex(value, index);
}

export function getDashPickUpCardTitle(type: DashPickUpType, mode: 'normal' | 'ritm') {
  return `${PICK_UP_MENUS[type].label} ${mode === 'normal' ? '' : '- Ritmo de vendas'}`;
}

export function getPickUpTableCellValueSalesRitm(value: number, colId: string): string {
  if (colId === 'revenueShare' || colId === 'roomNightShare') {
    return formatPercentage(value);
  }
  let index = DashIndex.REVENUE;
  if (colId.indexOf('average') > -1) {
    index = DashIndex.DAILY_AVERAGE;
  } else if (colId.indexOf('roomNight') > -1) {
    index = DashIndex.ROOM_NIGHT;
  } else if (colId.indexOf('revPar') > -1) {
    index = DashIndex.REV_PAR;
  } else if (colId.indexOf('occupation') > -1) {
    index = DashIndex.OCCUPATION;
  }
  return getValueHumanReadByIndex(value, index);
}

export function mountApiV1GetDashPickUpHotelRequest(
  index: DashIndex,
  pickUpFilters: DashPickUpOldFilters,
  segments: any[] = [],
  channels: any[] = [],
) {
  const { startDate, endDate, perspective, granularity } = pickUpFilters;

  const params: any = {
    startDate: formatDateApi(startDate),
    endDate: formatDateApi(endDate),
    index,
    granularity,
    perspective,
  };

  if (segments.length > 0) {
    params.segments = segments.map((x) => x._id);
  }

  if (channels.length > 0) {
    params.channels = channels.map((x) => x._id);
  }

  return params;
}

export function mountApiV1GetDashPickUpHotelRitmRequest(
  index: DashIndex,
  pickUpFilters: DashPickUpOldFilters,
  segments: any[] = [],
  channels: any[] = [],
) {
  const { startDateRitm, perspectiveRitm, granularity, startDateBooking, endDateBooking } =
    pickUpFilters;

  const params: any = {
    startDate: formatDateApi(startDateRitm),
    endDate: formatDateApi(startDateRitm),
    startDateBooking: formatDateApi(startDateBooking),
    endDateBooking: formatDateApi(endDateBooking),
    index,
    granularity,
    perspective: perspectiveRitm,
  };

  if (segments.length > 0) {
    params.segments = segments.map((x) => x._id);
  }

  if (channels.length > 0) {
    params.channels = channels.map((x) => x._id);
  }

  return params;
}
