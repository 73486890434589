import { RootState } from '@/redux';
import { UserSessionHotel } from '@/types/models/user-session';
import { apiV1CrudsService } from '@/utils/api';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

type SelectedHotel = UserSessionHotel | null;

export interface LegacyDataSliceState {
  selectedHotel: SelectedHotel | null;
  loading: boolean;
  importedPickUps: {
    _id: string;
    date: string; // ISO date
    createdAt: string; // ISO date
  }[];
  importedPickUpsGroupedByMonth: {
    count: number;
    month: string; // ISO date
  }[];
}

const initialState: LegacyDataSliceState = {
  loading: false,
  selectedHotel: null,
  importedPickUps: [],
  importedPickUpsGroupedByMonth: [],
};

const legacyDataSlice = createSlice({
  name: 'legacyData',
  initialState,
  reducers: {
    setLegacyDataSelectedHotel(state, action: PayloadAction<SelectedHotel>) {
      state.selectedHotel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLegacyData.pending, (state) => {
        state.loading = true;
        state.importedPickUps = [];
        state.importedPickUpsGroupedByMonth = [];
      })
      .addCase(fetchLegacyData.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.importedPickUps = action.payload.importedPickUps;
        state.importedPickUpsGroupedByMonth = action.payload.importedPickUpsGroupedByMonth;
      })
      .addCase(fetchLegacyData.rejected, (state) => {
        state.loading = true;
      });
  },
});

export const fetchLegacyData = createAsyncThunk<any, void, { state: RootState }>(
  'fetchLegacyData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { selectedHotel } = getState().legacyData;

      if (!selectedHotel) return rejectWithValue('No hotel selected');

      const response = await apiV1CrudsService.get(`/hotels/${selectedHotel?._id}/legacy-data`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const { setLegacyDataSelectedHotel: setLegacyDataHotel } = legacyDataSlice.actions;
export default legacyDataSlice.reducer;
