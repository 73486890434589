import { GenericIdName } from '../models/generic-id-name';

export enum AppUsagePage {
  HOME = 'HOME',
  HOME_V2 = 'HOME_V2',
  RANKING = 'RANKING',
  CONCURRENCY = 'CONCURRENCY',
  BENCHMARK = 'BENCHMARK',
  PICKUP_HOTELS = 'PICKUP_HOTELS',
  PICKUP_SEGMENTS = 'PICKUP_SEGMENTS',
  PICKUP_CHANNELS = 'PICKUP_CHANNELS',
  PICKUP_V2 = 'PICKUP_V2',
  PICKUP_V2_RITM = 'PICKUP_V2_RITM',
  EMISSIONS_HOTELS = 'EMISSIONS_HOTELS',
  EMISSIONS_SEGMENTS = 'EMISSIONS_SEGMENTS',
  EMISSIONS_CHANNELS = 'EMISSIONS_CHANNELS',
  RMS_FLUCTUATIONS = 'RMS_FLUCTUATIONS',
  RMS_FLUCTUATIONS_CALENDAR = 'RMS_FLUCTUATIONS_CALENDAR',
  RMS_FLUCTUATIONS_RULES = 'RMS_FLUCTUATIONS_RULES',
  CLEANING_MANAGEMENT = 'CLEANING_MANAGEMENT',
  GOALS = 'GOALS',
  UNKNOWN = 'UNKNOWN',
  GRI = 'GRI',
}

/**
 * @description Obtém o valor do enum human readable
 */
export function getAppUsagePageHumanRead(page: AppUsagePage) {
  switch (page) {
    case AppUsagePage.HOME:
      return 'Home';
    case AppUsagePage.HOME_V2:
      return 'Home (V2)';
    case AppUsagePage.RANKING:
      return 'Ranking';
    case AppUsagePage.CONCURRENCY:
      return 'Concorrência';
    case AppUsagePage.BENCHMARK:
      return 'Comparativos';
    case AppUsagePage.PICKUP_HOTELS:
      return 'Pick-Up V1 (hotéis)';
    case AppUsagePage.PICKUP_SEGMENTS:
      return 'Pick-Up V1 (segmentos)';
    case AppUsagePage.PICKUP_CHANNELS:
      return 'Pick-Up V1 (canais)';
    case AppUsagePage.PICKUP_V2:
      return 'Pick-Up V2';
    case AppUsagePage.PICKUP_V2_RITM:
      return 'Pick-Up V2 (ritmo)';
    case AppUsagePage.EMISSIONS_HOTELS:
      return 'Emissões (hotéis)';
    case AppUsagePage.EMISSIONS_SEGMENTS:
      return 'Emissões (segmentos)';
    case AppUsagePage.EMISSIONS_CHANNELS:
      return 'Emissões (canais)';
    case AppUsagePage.RMS_FLUCTUATIONS:
      return 'Flutuações (lista)';
    case AppUsagePage.RMS_FLUCTUATIONS_CALENDAR:
      return 'Flutuações (calendário)';
    case AppUsagePage.RMS_FLUCTUATIONS_RULES:
      return 'Flutuações (regras)';
    case AppUsagePage.CLEANING_MANAGEMENT:
      return 'Gestão de Limpeza';
    case AppUsagePage.UNKNOWN:
      return 'Desconhecido (não mapeada)';
    case AppUsagePage.GOALS:
      return 'Metas Check-in (setup)';
    case AppUsagePage.GRI:
      return 'Relatório GRI';
    default:
      return page;
  }
}

/**
 * @description Mapeamento de rotas para o enum correspondente
 */
export function getAppUsageFromPath(path: string): AppUsagePage {
  switch (path) {
    case '/':
      return AppUsagePage.HOME;
    case '/v2':
      return AppUsagePage.HOME_V2;
    case '/dashboards/ranking':
      return AppUsagePage.RANKING;
    case '/dashboards/concurrency':
      return AppUsagePage.CONCURRENCY;
    case '/dashboards/benchmark':
      return AppUsagePage.BENCHMARK;
    case '/dashboards/pick-ups/hotels':
      return AppUsagePage.PICKUP_HOTELS;
    case '/dashboards/pick-ups/segments':
      return AppUsagePage.PICKUP_SEGMENTS;
    case '/dashboards/pick-ups/channels':
      return AppUsagePage.PICKUP_CHANNELS;
    case '/dashboards/pick-ups-v2/default':
      return AppUsagePage.PICKUP_V2;
    case '/dashboards/pick-ups-v2/ritm':
      return AppUsagePage.PICKUP_V2_RITM;
    case '/dashboards/emissions/hotels':
      return AppUsagePage.EMISSIONS_HOTELS;
    case '/dashboards/emissions/segments':
      return AppUsagePage.EMISSIONS_SEGMENTS;
    case '/dashboards/emissions/channels':
      return AppUsagePage.EMISSIONS_CHANNELS;
    case '/rms/fluctuations':
      return AppUsagePage.RMS_FLUCTUATIONS;
    case '/rms/fluctuations-calendar':
      return AppUsagePage.RMS_FLUCTUATIONS_CALENDAR;
    case '/rms/fluctuation-rules':
      return AppUsagePage.RMS_FLUCTUATIONS_RULES;
    case '/cleaning-management':
      return AppUsagePage.CLEANING_MANAGEMENT;
    case '/goals':
      return AppUsagePage.GOALS;
    case '/rms/gri':
      return AppUsagePage.GRI;
    default:
      return AppUsagePage.UNKNOWN;
  }
}

export function getPathFromAppUsage(appUsage: AppUsagePage): string {
  switch (appUsage) {
    case AppUsagePage.HOME_V2:
      return '/v2';
    case AppUsagePage.RANKING:
      return '/dashboards/ranking';
    case AppUsagePage.CONCURRENCY:
      return '/dashboards/concurrency';
    case AppUsagePage.BENCHMARK:
      return '/dashboards/benchmark';
    case AppUsagePage.PICKUP_HOTELS:
      return '/dashboards/pick-ups/hotels';
    case AppUsagePage.PICKUP_SEGMENTS:
      return '/dashboards/pick-ups/segments';
    case AppUsagePage.PICKUP_CHANNELS:
      return '/dashboards/pick-ups/channels';
    case AppUsagePage.PICKUP_V2:
      return '/dashboards/pick-ups-v2/default';
    case AppUsagePage.PICKUP_V2_RITM:
      return '/dashboards/pick-ups-v2/ritm';
    case AppUsagePage.EMISSIONS_HOTELS:
      return '/dashboards/emissions/hotels';
    case AppUsagePage.EMISSIONS_SEGMENTS:
      return '/dashboards/emissions/segments';
    case AppUsagePage.EMISSIONS_CHANNELS:
      return '/dashboards/emissions/channels';
    case AppUsagePage.RMS_FLUCTUATIONS:
      return '/rms/fluctuations';
    case AppUsagePage.RMS_FLUCTUATIONS_CALENDAR:
      return '/rms/fluctuations-calendar';
    case AppUsagePage.RMS_FLUCTUATIONS_RULES:
      return '/rms/fluctuation-rules';
    case AppUsagePage.CLEANING_MANAGEMENT:
      return '/cleaning-management';
    case AppUsagePage.GOALS:
      return '/goals';
    case AppUsagePage.GRI:
      return '/rms/gri';
    case AppUsagePage.HOME:
    default:
      return '/';
  }
}

export const allAppUsagePage: GenericIdName[] = Object.values(AppUsagePage).map((x) => ({
  _id: x,
  name: getAppUsagePageHumanRead(x),
}));
