import { configureStore } from '@reduxjs/toolkit';
import { EqualityFn, NoInfer, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // ou outra opção de armazenamento
import rootReducer from './rootReducer';
// @ts-ignore
import immutableTransform from 'redux-persist-transform-immutable';

const persistConfig = {
  transforms: [immutableTransform()],
  key: 'root',
  storage,
  whitelist: ['filters'],
  // whitelist: process.env.NODE_ENV === 'development' ? [] : ['filters'],
};

export type RootState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

// Hooks and Types exports:
export type AppDispatch = typeof store.dispatch;
interface useSelectorHook<TState> {
  <TSelected>(
    selector: (state: TState) => TSelected,
    equalityFn?: EqualityFn<NoInfer<TSelected>> | boolean
  ): TSelected;
}

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: useSelectorHook<RootState> = (selector, equalityFn) => {
  if (typeof equalityFn === 'boolean') equalityFn = equalityFn ? shallowEqual : undefined;
  return useSelector(selector, equalityFn as any);
};
