import { SettingsValueProps } from '@/types/general/app-settings';

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'bold',
  themeLayout: 'vertical',
  themeColorPresets: 'default',
  themeStretch: true,
};
