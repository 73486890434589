export enum DashEmissionType {
  HOTELS = 'hotels',
  SEGMENTS = 'segments',
  CHANNELS = 'channels',
}

export type DashEmissionMenu = {
  type: DashEmissionType;
  label: string;
  icon: string;
};

export type DashEmissionMonthData = {
  bookingMonth: string; // ISO Date
  revenue: number;
  revenueLastYear: number;
  revenueLYDelta: number;
  goal: number;
  otbRevenue: number;
  checkInnGoal: number;
  shareGoal: number;
  pickUpGoal: number;
  otbGoalDayOne: number;
  paceGoal: number;
  shareInUse: number;
  goalAchieved: number;
  goalPercentage: number;
};

export type DashEmissionWeekData = {
  startWeekAt: string; // ISO Date
  endWeekAt: string; // ISO Date
  slug: string;
  revenue: number;
  revenueLastYear: number;
  revenueLYDelta: number;
  goal: number;
  goalPercentage: number;
  goalAchieved: number;
};

export type DashEmissionWeekDataGroupedByBookingMonth = {
  bookingMonth: Date;
} & DashEmissionWeekData;

export type DashEmissionDayData = {
  date: string; // ISO Date
  revenue: number;
  revenueLastYear: number;
  revenueLYDelta: number;
  goal: number;
  goalPercentage: number;
  goalAchieved: number;
};

export type DashEmissionHotelData = {
  hotel: {
    _id: any;
    name: string;
    slug: string;
  };
  revenue: number;
  revenueLastYear: number;
  revenueLYDelta: number;
  goal: number;
  goalPercentage: number;
  goalAchieved: number;
};

export type DashEmissionRegionalData = {
  regional: {
    _id: any;
    name: string;
  };
  revenue: number;
  revenueLastYear: number;
  revenueLYDelta: number;
  goal: number;
  goalPercentage: number;
  goalAchieved: number;
};

export type DashEmissionCityData = {
  city: {
    _id: any;
    name: string;
  };
  revenue: number;
  revenueLastYear: number;
  revenueLYDelta: number;
  goal: number;
  goalPercentage: number;
  goalAchieved: number;
};
