import { GenericIdName } from '@/types/models/generic-id-name';

export enum DashGranularity {
  DAY = 'DAY',
  MONTH = 'MONTH',
}

export function getDashGranularitHumanRead(value: DashGranularity) {
  switch (value) {
    case DashGranularity.DAY:
      return 'Dia';
    case DashGranularity.MONTH:
      return 'Mês';
    default:
      return value;
  }
}

export const allDashGranularity: GenericIdName[] = Object.values(DashGranularity).map((x) => ({
  _id: x,
  name: getDashGranularitHumanRead(x),
}));
