import { GenericIdName } from '@/types/models/generic-id-name';

export enum DashIndex {
  REVENUE = 'REVENUE',
  ROOM_NIGHT = 'ROOM_NIGHT',
  DAILY_AVERAGE = 'DAILY_AVERAGE',
  OCCUPATION = 'OCCUPATION',
  REV_PAR = 'REV_PAR',
  RESERVATIONS = 'RESERVATIONS',
  PAX = 'PAX',
  PAX_CHD = 'PAX_CHD',
  LOS = 'LOS',
  REVENUE_FB = 'REVENUE_FB',
  REVENUE_DAILY = 'REVENUE_DAILY',
  FEE = 'FEE',
}

export function getDashIndexHumanRead(index: DashIndex) {
  switch (index) {
    case DashIndex.REVENUE:
      return 'Receita';
    case DashIndex.ROOM_NIGHT:
      return 'RN';
    case DashIndex.DAILY_AVERAGE:
      return 'DM';
    case DashIndex.REV_PAR:
      return 'RevPar';
    case DashIndex.OCCUPATION:
      return 'OCC';
    case DashIndex.PAX:
      return 'Pax';
    case DashIndex.PAX_CHD:
      return 'Child';
    case DashIndex.RESERVATIONS:
      return 'Res.';
    case DashIndex.LOS:
      return 'LOS';
    case DashIndex.REVENUE_FB:
      return 'Receita A&B';
    case DashIndex.REVENUE_DAILY:
      return 'Receita diárias';
    case DashIndex.FEE:
      return 'Taxas'; // Ex, taxa de iss, taxa de turismo
    default:
      return index;
  }
}

export const allDashIndexes: GenericIdName[] = Object.values(DashIndex).map((x) => ({
  _id: x,
  name: getDashIndexHumanRead(x),
}));

export function dashIndexIsAverage(index: DashIndex) {
  return (
    index === DashIndex.DAILY_AVERAGE ||
    index === DashIndex.OCCUPATION ||
    index === DashIndex.REV_PAR ||
    index === DashIndex.LOS
  );
}

export function getDashIndexFieldName(index: DashIndex) {
  switch (index) {
    case DashIndex.REVENUE:
      return 'revenue';
    case DashIndex.REVENUE_FB:
      return 'revenueFoodAndBeverage';
    case DashIndex.REVENUE_DAILY:
      return 'revenueDaily';
    case DashIndex.FEE:
      return 'feeAmount';
    case DashIndex.ROOM_NIGHT:
      return 'roomNight';
    case DashIndex.DAILY_AVERAGE:
      return 'dailyAverage';
    case DashIndex.REV_PAR:
      return 'revPar';
    case DashIndex.OCCUPATION:
      return 'occupation';
    case DashIndex.PAX:
      return 'pax';
    case DashIndex.PAX_CHD:
      return 'paxChd';
    case DashIndex.RESERVATIONS:
      return 'reservations';
    case DashIndex.LOS:
      return 'los';
    default:
      throw new Error('Invalid index');
  }
}

export const allPickUpDashIndexes = Object.values(DashIndex);

/**
 * @description Apenas os índices que possuem metas de checkin
 */
export const indexesWithGoals = [
  DashIndex.REVENUE,
  DashIndex.DAILY_AVERAGE,
  DashIndex.ROOM_NIGHT,
  DashIndex.OCCUPATION,
  DashIndex.REV_PAR,
];
