export enum ReservationStatus {
  CONSOLIDATED = 'CONSOLIDATED',
  CANCELED = 'CANCELED',
}

export function getReservationStatusHumanRead(status: ReservationStatus) {
  switch (status) {
    case ReservationStatus.CONSOLIDATED:
      return 'Consolidadas/Forecast';
    case ReservationStatus.CANCELED:
      return 'Canceladas';
    default:
      return status;
  }
}
