import { FluctuationRuleBar } from '@/types/models/fluctuation-rule-bar';
import { formatPercentage } from '@/utils/number';
import { Box, Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';

interface Props {
  bar: FluctuationRuleBar | null;
  large?: boolean;
}
export default function BarCell({ bar, large = false }: Props) {
  const tooltipText = useMemo(
    () =>
      !!bar?._id
        ? `${bar.rule?.name} (${formatPercentage(bar.occupationStartRange)} a ${formatPercentage(
            bar.occupationEndRange
          )})`
        : 'Nenhuma BAR adequada',
    [bar]
  );
  if (!bar)
    return (
      <Tooltip arrow placement="top" followCursor title={tooltipText}>
        <span>N/A</span>
      </Tooltip>
    );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        minWidth: 50,
        minHeight: 20,
        background: bar.color,
        borderRadius: 1,
        px: 2,
      }}
    >
      <Tooltip arrow placement="top" followCursor title={tooltipText}>
        <Typography variant={large ? 'h4' : 'caption'} sx={{ color: '#FFFF' }}>
          {bar.name}
        </Typography>
      </Tooltip>
    </Box>
  );
}
