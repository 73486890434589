import Iconify from '@/components/Iconify';
import Scrollbar from '@/components/Scrollbar';
import BarCell from '@/components/rms/fluctuation-table/BarCell';
import { RMS_ICON } from '@/config/icons';
import { defaultTableProps } from '@/config/table-config';
import { FluctuationLog } from '@/types/models/fluctuation-log';
import { FluctuationPrice } from '@/types/models/fluctuation-price';
import { getRoomTypeCategoryOccupancyString } from '@/types/models/room-type-category';
import { formatDate } from '@/utils/dates';
import { formatCurrency } from '@/utils/number';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';

interface Props {
  fluctuationLog: FluctuationLog;
}

export default function FluctuationLogDetailsContainer({ fluctuationLog }: Props) {
  const { fluctuation } = fluctuationLog;

  const columns = useMemo<GridColDef<FluctuationPrice>[]>(
    () => [
      {
        field: 'roomTypeCategory.roomType.name',
        headerAlign: 'center',
        headerName: 'Quarto',
        flex: 2,
        type: 'string',
        valueGetter: ({ row }) =>
          `${row.roomTypeCategory?.roomType?.name} - ${getRoomTypeCategoryOccupancyString(
            row.roomTypeCategory?.occupancy
          )} ${
            row.roomTypeCategory.isHotelDefault
              ? '(Padrão do hotel)'
              : row.roomTypeCategory.isRoomTypeDefault
              ? '(Padrão do quarto)'
              : ''
          }`,
      },
      {
        field: 'suggestedPrice',
        headerAlign: 'center',
        headerName: 'Sugestão',
        flex: 1,
        type: 'number',
        renderCell: ({ value, row }) =>
          row.usingRoomTypeMinPrice ? (
            <Tooltip arrow placement="top" title="Usando o preço mínimo do tipo de quarto">
              <span>
                <b>{formatCurrency(value, 2)}</b>
              </span>
            </Tooltip>
          ) : (
            <span>{formatCurrency(value, 2)}</span>
          ),
      },
      {
        field: 'appliedPrice',
        headerAlign: 'center',
        headerName: 'Valor aplicado',
        flex: 1.5,
        type: 'number',
        renderCell: ({ value, row }) =>
          row.appliedPrice > 0 ? <span>{formatCurrency(value, 2)}</span> : <span>N/A</span>,
      },
      {
        field: 'customPrice',
        headerAlign: 'center',
        headerName: 'Valor personalizado',
        flex: 2,
        type: 'number',
        renderCell: ({ value, row }) =>
          row.usingCustomPrice ? <span>{formatCurrency(value, 2)}</span> : <span>N/A</span>,
      },
    ],
    []
  );

  return (
    <Card elevation={3} sx={{ py: 1, mt: 3 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'primary' }} aria-label="recipe">
            <Iconify icon={RMS_ICON} width={30} height={30} />
          </Avatar>
        }
        title="Registro histórico de flutuação"
        subheader={`Valores aplicados por categoria de acomodação`}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', paddingBottom: 2 }}
      />
      <CardContent>
        <Paper sx={{ width: '100%' }}>
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xs={12} md={12}>
              <BarCell large bar={fluctuation?.bar} />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                disabled
                fullWidth
                value={formatDate(fluctuation.appliedPricesAt)}
                label="Data da integração"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                disabled
                fullWidth
                value={fluctuation.bar?.rule?.name}
                label="Nome da regra"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                disabled
                fullWidth
                value={fluctuation.ratePlan.name}
                label="Plano tarifário"
              />
            </Grid>
            {/* FIXME: */}
            {/* <Grid item xs={12} md={3}>
              <TextField
                disabled
                fullWidth
                value={formatPercentage(fluctuation.indexes.occupation)}
                label="Ocupação"
              />
            </Grid> */}
            <Grid item xs={12} md={3}>
              <TextField
                disabled
                fullWidth
                value={formatCurrency(fluctuation.indexes.revenue)}
                label="Receita do dia"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField disabled fullWidth value={fluctuation.indexes.roomNight} label="RNs" />
            </Grid>
            {/* FIXME: */}
            {/* <Grid item xs={12} md={3}>
              <TextField
                disabled
                fullWidth
                value={formatCurrency(fluctuation.indexes.revPar)}
                label="RevPar"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                disabled
                fullWidth
                value={formatCurrency(fluctuation.indexes.dailyAverage)}
                label="Diária Média"
              />
            </Grid> */}
          </Grid>
          <Divider sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mx: 1 }}>
              Valores aplicados por categoria de acomodação
            </Typography>
          </Divider>
          <Scrollbar sx={{ width: 'auto' }}>
            <DataGrid<FluctuationPrice>
              {...defaultTableProps}
              paginationMode="client"
              sortingMode="client"
              rows={fluctuation.prices}
              columns={columns}
              getRowClassName={({ row }) => (row.roomTypeCategory?.isRoomTypeDefault ? 'bold' : '')}
            />
          </Scrollbar>
        </Paper>
      </CardContent>
    </Card>
  );
}
