import Label from './Label';

const { REACT_APP_VERSION, REACT_APP_ENV_NAME, NODE_ENV } = process.env;
const color =
  NODE_ENV === 'development' ? 'success' : REACT_APP_ENV_NAME === 'Produção' ? 'error' : 'primary';

export default function AppVersionLabel() {
  return (
    <Label sx={{ mr: 1 }} color={color}>
      v{REACT_APP_VERSION} ({REACT_APP_ENV_NAME})
    </Label>
  );
}
