import { useSession } from '@/hooks/auth/useSession';
import { getNameInitials } from '@/utils/string';
import Avatar, { Props as AvatarProps } from './Avatar';

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useSession();

  return (
    <Avatar src={user?.profilePhoto?.url || ''} alt="avatar" color="primary" {...other}>
      {getNameInitials(user.name)}
    </Avatar>
  );
}
