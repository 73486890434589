import { RootState } from '@/redux';
import { DashHomeV2Data } from '@/types/dashboards/home-v2';
import { DashGroup } from '@/types/enums/dashboards/dash-group.enum';
import { apiV1DashboardService } from '@/utils/api';
import { formatDateApi } from '@/utils/dates';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';

interface DashHomeV2SliceState {
  tableData: DashHomeV2Data[];
  loadingTableData: boolean;
}

const initialState: DashHomeV2SliceState = {
  tableData: [],
  loadingTableData: false,
};

const homeV2Slice = createSlice({
  name: 'homeV2',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeV2TableData.pending, (state) => {
        state.loadingTableData = true;
      })
      .addCase(fetchHomeV2TableData.fulfilled, (state, action: PayloadAction<DashHomeV2Data[]>) => {
        state.loadingTableData = false;
        state.tableData = action.payload;
      })
      .addCase(fetchHomeV2TableData.rejected, (state) => {
        state.loadingTableData = false;
        state.tableData = [];
      });
  },
});

export const fetchHomeV2TableData = createAsyncThunk<DashHomeV2Data[], void, { state: RootState }>(
  'fetchHomeV2TableData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { commonFilters } = getState().filters;
      const {
        startDate,
        endDate,
        granularity,
        perspective,
        segments = [],
        channels = [],
        indexes = [],
        showGoals,
        showLastMonth,
        showLastYear,
        reservationStatus,
        group,
      } = commonFilters;

      const params: any = {
        startDate: formatDateApi(startDate),
        endDate: formatDateApi(endDate),
        granularity,
        perspective,
        showGoals,
        showLastMonth,
        showLastYear,
        group,
        indexes,
        status: reservationStatus,
        channels: [],
        segments: [],
      };

      if (group === DashGroup.CHANNELS) {
        params.channels = channels.filter((x) => !x.isGroup).map((x) => x._id);
      } else if (group === DashGroup.CHANNEL_GROUPS) {
        params.channels = channels.filter((x) => x.isGroup).map((x) => x._id);
      } else if (group === DashGroup.SEGMENTS) {
        params.segments = segments.filter((x) => !x.isGroup).map((x) => x._id);
      } else if (group === DashGroup.SEGMENT_GROUPS) {
        params.segments = segments.filter((x) => x.isGroup).map((x) => x._id);
      }

      const { data } = await apiV1DashboardService.get(
        `/home/v2?${stringify(params, { arrayFormat: 'bracket' })}`,
      );

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export default homeV2Slice.reducer;
