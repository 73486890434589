import Iconify from '@/components/Iconify';
import Scrollbar from '@/components/Scrollbar';
import { HOTEL_LEGACY_DATA_ICON } from '@/config/icons';
import { defaultTableProps } from '@/config/table-config';
import { useAppSelector } from '@/redux';
import { LegacyDataSliceState } from '@/redux/slices/legacyDataSlice';
import { formatDate } from '@/utils/dates';
import { Avatar, Card, CardContent, CardHeader, Paper } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';

type GridType = LegacyDataSliceState['importedPickUps'][0];

export default function LegacyDataPickUpDayContainer() {
  const { loading, importedPickUps } = useAppSelector((state) => state.legacyData);

  const total = importedPickUps.length;
  const lastItem = importedPickUps[importedPickUps.length - 1];

  const columns = useMemo<GridColDef<GridType>[]>(
    () => [
      {
        field: '_id',
        headerName: 'ID',
        flex: 1,
        type: 'string',
        hide: true,
      },
      {
        field: 'date',
        headerName: 'Data',
        flex: 1,
        type: 'date',
        renderCell: ({ value }) => formatDate(value, 'dd/MM/yyyy'),
      },
      {
        field: 'createdAt',
        headerName: 'Data de importação',
        flex: 1,
        type: 'date',
        renderCell: ({ value }) => formatDate(value),
      },
    ],
    []
  );

  return (
    <Card elevation={3} sx={{ py: 1, mt: 3 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'primary' }} aria-label="recipe">
            <Iconify icon={HOTEL_LEGACY_DATA_ICON} width={25} height={25} />
          </Avatar>
        }
        title="Histórico de importação (IO via Scraping) de pick-ups - Detalhes por dia"
        subheader={'Estes são os dados necessários para cálculo de metas de emissão (LY)'}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', paddingBottom: 2 }}
      />
      <CardContent>
        <Paper sx={{ width: '100%' }}>
          <p>
            <b>Total:</b> {total} dias importados do IO
          </p>
          {!!lastItem?.date ? (
            <p>
              <b>Último dia importado:</b> {formatDate(lastItem.date, 'dd/MM/yyyy')}
            </p>
          ) : null}
          <br />
          <Scrollbar sx={{ width: 'auto' }}>
            <DataGrid<GridType>
              {...defaultTableProps}
              paginationMode="client"
              sortingMode="client"
              rows={importedPickUps}
              columns={columns}
              loading={loading}
            />
          </Scrollbar>
        </Paper>
      </CardContent>
    </Card>
  );
}
