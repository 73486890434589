import { GenericIdName } from '@/types/models/generic-id-name';

export enum DashPickUpPerspective {
  ABSOLUTE = 'ABSOLUTE',
  EVOLUTION = 'EVOLUTION',
}

export function getDashPickUpPerspectiveHumanRead(value: DashPickUpPerspective) {
  switch (value) {
    case DashPickUpPerspective.ABSOLUTE:
      return 'Valor absoluto';
    case DashPickUpPerspective.EVOLUTION:
      return 'Evolução';
    default:
      return value;
  }
}

export const allDashPickUpPerspectives: GenericIdName[] = Object.values(DashPickUpPerspective).map(
  (x) => ({
    _id: x,
    name: getDashPickUpPerspectiveHumanRead(x),
  })
);
