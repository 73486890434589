import { useSession } from '@/hooks/auth/useSession';
import { UserPermission } from '@/types/enums/user-permission.enum';
import { User } from '@/types/models/user';
import { Navigate } from 'react-router-dom';

export function userHasPermission(permission: UserPermission, user: User) {
  return user.roles.some((x) => x.permissions.includes(permission)) || user.isAdmin;
}

export default function RoleGuard({
  permission,
  children,
}: {
  permission: UserPermission;
  children: JSX.Element;
}) {
  const { user } = useSession();

  if (!userHasPermission(permission, user)) {
    return <Navigate to="/403" replace />;
  }

  return children;
}
