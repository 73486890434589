import { RootState } from '@/redux';
import {
  ApiV1DashPickUpResponse,
  ApiV1DashPickUpRitmResponse,
} from '@/types/apiv1/responses/dashboards/api-v1-pick-up-responses';
import { DashGroup } from '@/types/enums/dashboards/dash-group.enum';
import { Filters } from '@/types/general/filters';
import { apiV1DashboardService } from '@/utils/api';
import { formatDateApi } from '@/utils/dates';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';

interface DashPickUpSliceState {
  loading: boolean;
  // Pick Up Geral
  pickUp: ApiV1DashPickUpResponse;
  // Pick Up Ritmo
  pickUpRitm: ApiV1DashPickUpRitmResponse;
}

const initialState: DashPickUpSliceState = {
  loading: false,
  pickUp: {
    data: [],
    chartData: [],
    bookingDates: [],
  },
  pickUpRitm: {
    data: [],
    chartData: [],
  },
};

const dashPickUpSlice = createSlice({
  name: 'dashPickUp',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashPickUp.pending, (state) => {
        // state.pickUp = initialState.pickUp;
        state.loading = true;
      })
      .addCase(
        fetchDashPickUp.fulfilled,
        (state, action: PayloadAction<ApiV1DashPickUpResponse>) => {
          state.loading = false;
          state.pickUp = action.payload;
        },
      )
      .addCase(fetchDashPickUp.rejected, (state, action) => {
        state.loading = false;
        state.pickUp = initialState.pickUp;
      })
      .addCase(fetchDashPickUpRitm.pending, (state) => {
        // state.pickUpRitm = initialState.pickUpRitm;
        state.loading = true;
      })
      .addCase(
        fetchDashPickUpRitm.fulfilled,
        (state, action: PayloadAction<ApiV1DashPickUpRitmResponse>) => {
          state.loading = false;
          state.pickUpRitm = action.payload;
        },
      )
      .addCase(fetchDashPickUpRitm.rejected, (state, action) => {
        state.loading = false;
        state.pickUpRitm = initialState.pickUpRitm;
      });
  },
});

export const fetchDashPickUp = createAsyncThunk<
  ApiV1DashPickUpResponse,
  void,
  { state: RootState }
>('pickUp/fetchDashPickUp', async (_, { rejectWithValue, getState }) => {
  try {
    const params = mountPickUpParams(getState().filters, 'default');

    const response = await apiV1DashboardService.get(
      `/pick-up?${stringify(params, { arrayFormat: 'bracket' })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashPickUpRitm = createAsyncThunk<
  ApiV1DashPickUpRitmResponse,
  void,
  { state: RootState }
>('pickUp/fetchDashPickUpRitm', async (_, { rejectWithValue, getState }) => {
  try {
    const params = mountPickUpParams(getState().filters, 'ritm');

    const response = await apiV1DashboardService.get(
      `/pick-up/ritm?${stringify(params, {
        arrayFormat: 'bracket',
      })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export default dashPickUpSlice.reducer;

function mountPickUpParams(filters: Filters, type: 'default' | 'ritm') {
  const { commonFilters, pickUpFilters } = filters;
  const { index, groups, reservationStatus, segments = [], channels = [] } = commonFilters;
  const {
    granularityEmission,
    startDateEmission,
    endDateEmission,
    granularityEmissionRitm,
    startDateEmissionRitm,
    endDateEmissionRitm,
    endDateBooking,
    startDateBooking,
    granularityBooking,
    perspective,
  } = pickUpFilters;

  const params: any = {
    index,
    perspective,
    startDateEmission: formatDateApi(startDateEmission),
    endDateEmission: formatDateApi(endDateEmission),
    granularityEmission,
    startDateBooking: formatDateApi(startDateBooking),
    endDateBooking: formatDateApi(endDateBooking),
    status: reservationStatus,
    groups,
    segments: [],
    segmentGroups: [],
    channels: [],
    channelGroups: [],
  };

  if (type === 'ritm') {
    params.startDateEmission = formatDateApi(startDateEmissionRitm);
    params.endDateEmission = formatDateApi(endDateEmissionRitm);
    params.granularityEmission = granularityEmissionRitm;
  } else {
    params.granularityBooking = granularityBooking;
  }

  if (groups.includes(DashGroup.SEGMENTS) || groups.includes(DashGroup.SEGMENT_GROUPS)) {
    params.segments = segments.filter((x) => !x.isGroup).map((x) => x._id);
    params.segmentGroups = segments.filter((x) => x.isGroup).map((x) => x._id);
  }

  if (groups.includes(DashGroup.CHANNELS) || groups.includes(DashGroup.CHANNEL_GROUPS)) {
    params.channels = channels.filter((x) => !x.isGroup).map((x) => x._id);
    params.channelGroups = channels.filter((x) => x.isGroup).map((x) => x._id);
  }

  return params;
}
