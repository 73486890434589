import { DocIllustration } from '@/assets';
import { SUPPORT_LINK } from '@/config/support-link';
import { useSession } from '@/hooks/auth/useSession';
import { Button, Stack, Typography } from '@mui/material';

export default function NavbarHelp() {
  const { user } = useSession();

  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}
    >
      <DocIllustration sx={{ width: 1 }} />

      <div>
        <Typography gutterBottom variant="subtitle1">
          Olá, {user.firstName}.
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          Precisa de ajuda?
        </Typography>
      </div>

      <Button href={SUPPORT_LINK} target="_blank" rel="noopener" variant="contained">
        Suporte
      </Button>
    </Stack>
  );
}
