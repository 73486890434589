import { apiV1ConcurrencyService, apiV1CrudsService } from '@/utils/api';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';

interface Props {
  url: string;
  title?: string;
  description?: string;
  onClose: VoidFunction;
  api?: 'crud' | 'concurrency';
}

export default function JsonViewer({
  onClose,
  url,
  title = 'Detalhes',
  description,
  api = 'crud',
}: Props) {
  const [data, setData] = useState();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const apiService = api === 'crud' ? apiV1CrudsService : apiV1ConcurrencyService;
        const { data } = await apiService.get(url);
        setData(data);
      } catch {
        setHasError(true);
        //
      }
    };

    if (!!url) {
      getData();
    } else {
      setHasError(false);
      setData(undefined);
    }
  }, [api, url]);

  return (
    <Dialog fullWidth maxWidth="lg" open={!!url} onClose={() => onClose()}>
      <DialogTitle sx={{ mb: 2 }}>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        {!!description && <DialogContentText>{description}</DialogContentText>}
        {!!hasError && <DialogContentText>Erro ao abrir conteúdo</DialogContentText>}
        <Box
          noValidate
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {data !== undefined ? (
            <ReactJson
              enableClipboard={false}
              displayDataTypes={false}
              displayObjectSize={false}
              quotesOnKeys={false}
              name="Objeto"
              src={data}
            />
          ) : (
            <CircularProgress sx={{ m: 6 }} />
          )}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => onClose()}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}
