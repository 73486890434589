import LoadingScreen from '@/components/loadings/LoadingScreen';
import { ElementType, Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export default function PublicRoutes() {
  return (
    <Routes>
      <Route path="auth">
        <Route path="login" element={<Login />} />
        <Route path="password-recovery" element={<ResetPassword />} />
        <Route path="password-recovery/:token" element={<ResetPasswordToken />} />
      </Route>
      <Route path="otp/:otpId" element={<Otp />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
}

const Login = Loadable(lazy(() => import('@/pages/auth/Login')));
const Otp = Loadable(lazy(() => import('@/pages/otp')));
const ResetPassword = Loadable(lazy(() => import('@/pages/auth/ResetPassword')));
const ResetPasswordToken = Loadable(lazy(() => import('@/pages/auth/ResetPasswordToken')));
