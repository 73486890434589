import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'simplebar/src/simplebar.css';
import 'src/styles/custom.css';

import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from '@/App';
import { CollapseDrawerProvider } from '@/contexts/CollapseDrawerContext';
import { SettingsProvider } from '@/contexts/SettingsContext';
import { persistor, store } from '@/redux';
import { PersistGate } from 'redux-persist/integration/react';

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SettingsProvider>
            <CollapseDrawerProvider>
              <App />
            </CollapseDrawerProvider>
          </SettingsProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
);
