export function downloadCsvFromData(data: string, fileName: string) {
  try {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    const urlBlob = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = urlBlob;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(urlBlob);
  } catch (error) {
    console.log(`downloadFromData LOG:  error:`, error);
    alert('Erro ao baixar arquivo.');
  }
}

export function downloadFromBlob(blob: Blob, fileName: string) {
  try {
    const urlBlob = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = urlBlob;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(urlBlob);
  } catch (error) {
    console.log(`downloadFromData LOG:  error:`, error);
    alert('Erro ao baixar arquivo.');
  }
}

export const convertToCSV = (data: any[]): string => {
  const header = Object.keys(data[0]).join(',');
  const rows = data.map((item) => Object.values(item).join(','));
  return [header, ...rows].join('\n');
};
