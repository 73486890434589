import FluctuationConcurrencyContainer from '@/components/rms/fluctuation-details/FluctuationConcurrencyContainer';
import FluctuationIndexesContainer from '@/components/rms/fluctuation-details/FluctuationIndexesContainer';
import { useAppDispatch, useAppSelector } from '@/redux';
import { closeFluctuationDetails, fetchRmsFlutuations } from '@/redux/slices/rmsSlice';
import { formatDate } from '@/utils/dates';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab } from '@mui/material';
import { useState } from 'react';
import FluctuationDecisionContainer from '../../../components/rms/fluctuation-details/FluctuationDecisionContainer';
import FluctuationHistoricContainer from '../../../components/rms/fluctuation-details/FluctuationHistoricContainer';
import FluctuationIntegrationContainer from '../../../components/rms/fluctuation-details/FluctuationIntegrationContainer';
import FluctuationPricesContainer from '../../../components/rms/fluctuation-details/FluctuationPricesContainer';

export default function FluctuationDetailsModal() {
  const [activeTab, setActiveTab] = useState<'1' | '2'>('1');
  const { fluctuationDetailsModalOpened } = useAppSelector((state) => state.rms!);

  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(closeFluctuationDetails());
    setActiveTab('1');
    dispatch(fetchRmsFlutuations());
  };

  if (!fluctuationDetailsModalOpened?.fluctuation?._id) {
    return null;
  }

  const { fluctuation } = fluctuationDetailsModalOpened!;

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle id="alert-dialog-title" sx={{ borderBottomStyle: 'dashed', pb: 2 }}>
        Detalhes flutuação {formatDate(fluctuation?.date, 'dd/MM/yyyy (EEEE)')}
      </DialogTitle>

      <DialogContent>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={(e, val) => setActiveTab(val)}>
              <Tab label="Geral" value="1" />
              <Tab label="Concorrência" value="2" />
              <Tab label="Histórico de alterações" value="3" />
              <Tab label="Logs de integrações Omnibees" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <>
              <FluctuationDecisionContainer fluctuation={fluctuation} />
              <FluctuationPricesContainer fluctuation={fluctuation} />
              <FluctuationIndexesContainer fluctuation={fluctuation} />
            </>
          </TabPanel>
          <TabPanel value="2">
            <FluctuationConcurrencyContainer fluctuation={fluctuation} />
          </TabPanel>
          <TabPanel value="3">
            <FluctuationHistoricContainer />
          </TabPanel>
          <TabPanel value="4">
            <FluctuationIntegrationContainer />
          </TabPanel>
        </TabContext>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
