import SettingsDrawer from '@/components/settings-drawer';
import { useAppSelector } from '@/redux';
import ThemeColorPresets from './ThemeColorPresets';
import ThemeContrast from './ThemeContrast';

interface Props {
  children: React.ReactNode;
}

export default function ThemeSettings({ children }: Props) {
  const { isLogged } = useAppSelector((state) => state.auth);
  return (
    <ThemeColorPresets>
      <ThemeContrast>
        {children}
        {isLogged && <SettingsDrawer />}
      </ThemeContrast>
    </ThemeColorPresets>
  );
}
