import { GenericIdName } from '@/types/models/generic-id-name';

export enum FluctuationType {
  PERCENTAGE = 'PERCENTAGE',
  FIXED = 'FIXED',
}

export function getFluctuationTypeHumanRead(variationType: FluctuationType) {
  switch (variationType) {
    case FluctuationType.FIXED:
      return 'R$';
    case FluctuationType.PERCENTAGE:
      return '%';
  }
}

export const allFluctuationTypes: GenericIdName[] = Object.values(FluctuationType).map((type) => ({
  _id: type,
  name: getFluctuationTypeHumanRead(type),
}));
