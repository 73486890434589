import { LabelColor } from '@/components/Label';
import { Fluctuation } from '@/types/models/fluctuation';
import { GenericIdName } from '@/types/models/generic-id-name';
import { formatDate } from '@/utils/dates';

export enum FluctuationStatus {
  DECISION_PENDING = 'DECISION_PENDING',
  QUEUED = 'QUEUED',
  UPDATED = 'UPDATED',
  DISABLED = 'DISABLED', // Desabilitado pelo usuario
  EXPIRED = 'EXPIRED',
  RMS_DISABLED = 'RMS_DISABLED',
  OMNIBEES_DISABLED = 'OMNIBEES_DISABLED',
  OMNIBEES_RATE_PLAN_UNLINKED = 'OMNIBEES_RATE_PLAN_UNLINKED',
  OMNIBEES_ROOM_TYPE_UNLINKED = 'OMNIBEES_ROOM_TYPE_UNLINKED',
  OMNIBEES_INCOMPATIBLE_ROOM_OR_RATE = 'OMNIBEES_INCOMPATIBLE_ROOM_OR_RATE',
  BAR_NOT_FOUND = 'BAR_NOT_FOUND',
  ERROR = 'ERROR',
}

export function getFluctuationsStatusHumanRead(status: FluctuationStatus) {
  switch (status) {
    case FluctuationStatus.RMS_DISABLED:
      return 'RMS desabilitado';
    case FluctuationStatus.OMNIBEES_DISABLED:
      return 'Offline';
    case FluctuationStatus.OMNIBEES_RATE_PLAN_UNLINKED:
      return 'Plano tarif. desvinculado';
    case FluctuationStatus.OMNIBEES_ROOM_TYPE_UNLINKED:
      return 'Acomodação desvinculada';
    case FluctuationStatus.OMNIBEES_INCOMPATIBLE_ROOM_OR_RATE:
      return 'Acomodação incompatível';
    case FluctuationStatus.DECISION_PENDING:
      return 'Decisão pendente';
    case FluctuationStatus.QUEUED:
      return 'Na fila';
    case FluctuationStatus.UPDATED:
      return 'Atualizado';
    case FluctuationStatus.ERROR:
      return 'Erro';
    case FluctuationStatus.DISABLED:
      return 'Desabilitada';
    case FluctuationStatus.EXPIRED:
      return 'Expirada';
    case FluctuationStatus.BAR_NOT_FOUND:
      return 'Nenhuma BAR';
    default:
      return status;
  }
}

export function getFluctuationsStatusDescriptionHumanRead(fluctuation: Fluctuation) {
  switch (fluctuation.status) {
    case FluctuationStatus.OMNIBEES_DISABLED:
      return 'Integração com Omnibees desabilitada';
    case FluctuationStatus.OMNIBEES_RATE_PLAN_UNLINKED:
      return 'Plano tarifário desvinculado da Omnibees';
    case FluctuationStatus.OMNIBEES_ROOM_TYPE_UNLINKED:
      return 'Acomodação desvinculada da Omnibees';
    case FluctuationStatus.OMNIBEES_INCOMPATIBLE_ROOM_OR_RATE:
      return 'Acomodação incompatível com plano tarifário na Omnibees';
    case FluctuationStatus.DECISION_PENDING:
      return 'Aguardando decisão do usuário';
    case FluctuationStatus.QUEUED:
      return 'Aguardando integração com Omnibees';
    case FluctuationStatus.UPDATED:
      return `Atualizado na Omnibees em ${formatDate(fluctuation.appliedPricesAt)}`;
    case FluctuationStatus.ERROR:
      return 'Erro ao atualizar na Omnibees';
    case FluctuationStatus.DISABLED:
      return 'Flutuação desabilitada';
    case FluctuationStatus.EXPIRED:
      return 'Flutuação expirada';
    case FluctuationStatus.BAR_NOT_FOUND:
      return 'Nenhuma BAR encontrada';
    case FluctuationStatus.RMS_DISABLED:
      return 'RMS desabilitado';
    default:
      return fluctuation.status;
  }
}

export function getFluctuationsStatusColor(status: FluctuationStatus): LabelColor {
  switch (status) {
    case FluctuationStatus.DECISION_PENDING:
      return 'warning';
    case FluctuationStatus.QUEUED:
      return 'info';
    case FluctuationStatus.UPDATED:
      return 'success';
    case FluctuationStatus.ERROR:
    case FluctuationStatus.DISABLED:
    case FluctuationStatus.OMNIBEES_RATE_PLAN_UNLINKED:
    case FluctuationStatus.OMNIBEES_ROOM_TYPE_UNLINKED:
    case FluctuationStatus.OMNIBEES_INCOMPATIBLE_ROOM_OR_RATE:
    case FluctuationStatus.BAR_NOT_FOUND:
      return 'error';
    default:
      return 'default';
  }
}

export const allFluctuationStatus: GenericIdName[] = Object.values(FluctuationStatus).map((x) => ({
  _id: x,
  name: getFluctuationsStatusHumanRead(x),
}));
