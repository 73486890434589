import { RootState } from '@/redux';
import { FluctuationLog } from '@/types/models/fluctuation-log';
import { IntegrationInLog } from '@/types/models/integration-in-log';
import { IntegrationOutLog } from '@/types/models/integration-out-log';
import { PickUpJob } from '@/types/models/pick-up-job';
import { RevenueJob } from '@/types/models/revenue-job';
import { RmsActionLog } from '@/types/models/rms-action-log';
import { UserSessionHotel } from '@/types/models/user-session';
import { apiV1CrudsService, apiV1RmsService } from '@/utils/api';
import { formatDateApi } from '@/utils/dates';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { endOfMonth, startOfMonth } from 'date-fns';
import { stringify } from 'query-string';

interface LogSliceState {
  actionLogs: {
    data: RmsActionLog[];
    loading: boolean;
  };
  fluctuationLogs: {
    data: FluctuationLog[];
    loading: boolean;
  };
  integrationInLogs: {
    data: IntegrationInLog[];
    loading: boolean;
  };
  integrationOutLogs: {
    data: IntegrationOutLog[];
    loading: boolean;
  };
  pickUpJobs: {
    data: PickUpJob[];
    loading: boolean;
    modalOpened: boolean;
    hotelsToImport: UserSessionHotel[];
    startDate: Date;
    endDate: Date;
  };
  revenueJobs: {
    data: RevenueJob[];
    loading: boolean;
    modalOpened: boolean;
    hotelsToImport: UserSessionHotel[];
    startDate: Date;
    endDate: Date;
  };
}

const initialState: LogSliceState = {
  actionLogs: {
    data: [],
    loading: false,
  },
  fluctuationLogs: {
    data: [],
    loading: false,
  },
  integrationInLogs: {
    data: [],
    loading: false,
  },
  integrationOutLogs: {
    data: [],
    loading: false,
  },
  pickUpJobs: {
    data: [],
    loading: false,
    modalOpened: false,
    hotelsToImport: [],
    startDate: new Date(),
    endDate: new Date(),
  },
  revenueJobs: {
    data: [],
    loading: false,
    modalOpened: false,
    hotelsToImport: [],
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  },
};

const logSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    // PickUp jobs:
    setPickUpJobModalOpened(state, action: PayloadAction<boolean>) {
      state.pickUpJobs.modalOpened = action.payload;
    },
    setPickUpJobHotels(state, action: PayloadAction<UserSessionHotel[]>) {
      state.pickUpJobs.hotelsToImport = action.payload;
    },
    setPickUpJobStartDate(state, action: PayloadAction<Date>) {
      state.pickUpJobs.startDate = action.payload;
    },
    setPickUpJobEndDate(state, action: PayloadAction<Date>) {
      state.pickUpJobs.endDate = action.payload;
    },
    // Revenue jobs:
    setRevenueJobModalOpened(state, action: PayloadAction<boolean>) {
      state.revenueJobs.modalOpened = action.payload;
    },
    setRevenueJobHotels(state, action: PayloadAction<UserSessionHotel[]>) {
      state.revenueJobs.hotelsToImport = action.payload;
    },
    setRevenueJobStartDate(state, action: PayloadAction<Date>) {
      state.revenueJobs.startDate = action.payload;
    },
    setRevenueJobEndDate(state, action: PayloadAction<Date>) {
      state.revenueJobs.endDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActionLogs.pending, (state) => {
        state.actionLogs.loading = true;
        state.actionLogs.data = [];
      })
      .addCase(fetchActionLogs.fulfilled, (state, action: PayloadAction<RmsActionLog[]>) => {
        state.actionLogs.loading = false;
        state.actionLogs.data = action.payload;
      })
      .addCase(fetchActionLogs.rejected, (state, action) => {
        state.actionLogs.loading = false;
      })
      .addCase(fetchFluctuationLogs.pending, (state) => {
        state.fluctuationLogs.loading = true;
        state.fluctuationLogs.data = [];
      })
      .addCase(fetchFluctuationLogs.fulfilled, (state, action: PayloadAction<FluctuationLog[]>) => {
        state.fluctuationLogs.loading = false;
        state.fluctuationLogs.data = action.payload;
      })
      .addCase(fetchFluctuationLogs.rejected, (state, action) => {
        state.fluctuationLogs.loading = false;
      })
      .addCase(fetchIntegrationInLogs.pending, (state) => {
        state.integrationInLogs.loading = true;
        state.integrationInLogs.data = [];
      })
      .addCase(
        fetchIntegrationInLogs.fulfilled,
        (state, action: PayloadAction<IntegrationInLog[]>) => {
          state.integrationInLogs.loading = false;
          state.integrationInLogs.data = action.payload;
        },
      )
      .addCase(fetchIntegrationInLogs.rejected, (state, action) => {
        state.integrationInLogs.loading = false;
      })
      .addCase(fetchIntegrationOutLogs.pending, (state) => {
        state.integrationOutLogs.loading = true;
        state.integrationOutLogs.data = [];
      })
      .addCase(
        fetchIntegrationOutLogs.fulfilled,
        (state, action: PayloadAction<IntegrationOutLog[]>) => {
          state.integrationOutLogs.loading = false;
          state.integrationOutLogs.data = action.payload;
        },
      )
      .addCase(fetchIntegrationOutLogs.rejected, (state, action) => {
        state.integrationOutLogs.loading = false;
      })
      .addCase(fetchPickUpJobs.pending, (state) => {
        state.pickUpJobs.loading = true;
      })
      .addCase(fetchPickUpJobs.fulfilled, (state, action: PayloadAction<PickUpJob[]>) => {
        state.pickUpJobs.loading = false;
        state.pickUpJobs.data = action.payload;
      })
      .addCase(fetchPickUpJobs.rejected, (state, action) => {
        state.pickUpJobs.loading = false;
      })
      .addCase(fetchRevenueJobs.pending, (state) => {
        state.revenueJobs.loading = true;
      })
      .addCase(fetchRevenueJobs.fulfilled, (state, action: PayloadAction<RevenueJob[]>) => {
        state.revenueJobs.loading = false;
        state.revenueJobs.data = action.payload;
      })
      .addCase(fetchRevenueJobs.rejected, (state, action) => {
        state.revenueJobs.loading = false;
      });
  },
});

export const fetchActionLogs = createAsyncThunk<RmsActionLog[], void, { state: RootState }>(
  'fetchActionLogs',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { logFilters } = getState().filters;
      const { actionLogOperation, startCreatedAt, endCreatedAt } = logFilters;

      const params: any = {
        startDate: formatDateApi(startCreatedAt),
        endDate: formatDateApi(endCreatedAt),
      };

      if (actionLogOperation !== 'ALL') {
        params.operation = actionLogOperation;
      }

      const response = await apiV1RmsService.get<RmsActionLog[]>(
        `/action-logs?${stringify(params, { arrayFormat: 'bracket' })}`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchFluctuationLogs = createAsyncThunk<FluctuationLog[], void, { state: RootState }>(
  'fetchFluctuationLogs',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { commonFilters, logFilters } = getState().filters;
      const { startDate, endDate } = commonFilters;
      const { actionLogOperation, startCreatedAt, endCreatedAt, hasError } = logFilters;

      const params: any = {
        startDate: formatDateApi(startDate),
        endDate: formatDateApi(endDate),
        startCreatedAt: formatDateApi(startCreatedAt),
        endCreatedAt: formatDateApi(endCreatedAt),
      };

      if (actionLogOperation !== 'ALL') {
        params.operation = actionLogOperation;
      }

      if (hasError !== null) {
        params.hasError = hasError;
      }

      const response = await apiV1RmsService.get<FluctuationLog[]>(
        `/fluctuation-logs?${stringify(params, { arrayFormat: 'bracket' })}`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchIntegrationInLogs = createAsyncThunk<
  IntegrationInLog[],
  void,
  { state: RootState }
>('fetchIntegrationInLogs', async (_, { rejectWithValue, getState }) => {
  try {
    const { logFilters } = getState().filters;
    const { startCreatedAt, endCreatedAt, hasError } = logFilters;

    const params: any = {
      startDate: formatDateApi(startCreatedAt),
      endDate: formatDateApi(endCreatedAt),
    };

    if (hasError !== null) {
      params.hasError = hasError;
    }

    const response = await apiV1CrudsService.get<IntegrationInLog[]>(
      `/integration-in-logs?${stringify(params, { arrayFormat: 'bracket' })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchIntegrationOutLogs = createAsyncThunk<
  IntegrationOutLog[],
  void,
  { state: RootState }
>('fetchIntegrationOutLogs', async (_, { rejectWithValue, getState }) => {
  try {
    const { logFilters } = getState().filters;
    const { startCreatedAt, endCreatedAt, httpService, url, requestBody, responseBody, hasError } =
      logFilters;

    const params: any = {
      startDate: formatDateApi(startCreatedAt),
      endDate: formatDateApi(endCreatedAt),
    };

    if (hasError !== null) {
      params.hasError = hasError;
    }

    if (!!httpService) {
      params.service = httpService;
    }

    if (!!responseBody) {
      params.responseBody = responseBody;
    }

    if (!!requestBody) {
      params.requestBody = requestBody;
    }

    if (!!url) {
      params.url = url;
    }

    const response = await apiV1CrudsService.get<IntegrationOutLog[]>(
      `/integration-out-logs?${stringify(params, { arrayFormat: 'bracket' })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchPickUpJobs = createAsyncThunk<PickUpJob[], void, { state: RootState }>(
  'fetchPickUpJobs',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { logFilters } = getState().filters;
      const { startCreatedAt, endCreatedAt, hasError } = logFilters;

      const params: any = {
        startDate: formatDateApi(startCreatedAt),
        endDate: formatDateApi(endCreatedAt),
      };

      if (hasError !== null) {
        params.hasError = hasError;
      }

      const response = await apiV1CrudsService.get<PickUpJob[]>(
        `/pick-up-jobs?${stringify(params, { arrayFormat: 'bracket' })}`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchRevenueJobs = createAsyncThunk<RevenueJob[], void, { state: RootState }>(
  'fetchRevenueJobs',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { logFilters } = getState().filters;
      const { startCreatedAt, endCreatedAt, hasError } = logFilters;

      const params: any = {
        startDate: formatDateApi(startCreatedAt),
        endDate: formatDateApi(endCreatedAt),
      };

      if (hasError !== null) {
        params.hasError = hasError;
      }

      const response = await apiV1CrudsService.get<RevenueJob[]>(
        `/revenue-jobs?${stringify(params, { arrayFormat: 'bracket' })}`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const {
  setPickUpJobModalOpened,
  setPickUpJobHotels,
  setPickUpJobEndDate,
  setPickUpJobStartDate,
  setRevenueJobModalOpened,
  setRevenueJobHotels,
  setRevenueJobStartDate,
  setRevenueJobEndDate,
} = logSlice.actions;
export default logSlice.reducer;
