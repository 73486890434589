import { GenericIdName } from '../models/generic-id-name';

export enum PickUpJobOperation {
  COPY_FROM_PREV = 'COPY_FROM_PREV',
  COPY_FROM_NEXT = 'COPY_FROM_NEXT',
  S3_IMPORT = 'S3_IMPORT',
}

export function getPickUpJobOperationHumanRead(status: PickUpJobOperation): string {
  switch (status) {
    case PickUpJobOperation.S3_IMPORT:
      return 'Importar do S3 (backup)';
    case PickUpJobOperation.COPY_FROM_PREV:
      return 'Copiar dia anterior (erro HITS)';
    case PickUpJobOperation.COPY_FROM_NEXT:
      return 'Copiar dia seguinte (erro HITS)';
    default:
      return status;
  }
}

export const allPickUpJobOperationDropdownOptions: GenericIdName[] = Object.values(
  PickUpJobOperation,
).map((x) => ({
  _id: x,
  name: getPickUpJobOperationHumanRead(x),
}));

export function getPickUpJobOperationHumanReadDescription(status: PickUpJobOperation): string {
  switch (status) {
    case PickUpJobOperation.S3_IMPORT:
      return 'Faz download dos dados salvos no S3 (backup) e importa para o banco de dados.';
    case PickUpJobOperation.COPY_FROM_PREV:
      return 'Duplica o valor do dia anterior. Útil quando o HITS apresentou falha nesse dia. Evolução nesse dia será zerada.';
    case PickUpJobOperation.COPY_FROM_NEXT:
      return 'Duplica o valor do dia seguinte. Útil quando o HITS apresentou falha nesse dia. Evolução nesse dia será zerada.';
    default:
      return status;
  }
}
