import SelectStatic from '@/components/forms/select/SelectStatic';
import SelectStaticMultiple from '@/components/forms/select/SelectStaticMultiple';
import { useNotification } from '@/hooks/useNotification';
import { useAppDispatch, useAppSelector } from '@/redux';
import {
  fetchPickUpJobs,
  setPickUpJobEndDate,
  setPickUpJobHotels,
  setPickUpJobModalOpened,
  setPickUpJobStartDate,
} from '@/redux/slices/logSlice';
import {
  allPickUpJobOperationDropdownOptions,
  getPickUpJobOperationHumanReadDescription,
  PickUpJobOperation,
} from '@/types/enums/pick-up-job-operation.enum';
import { GenericIdName } from '@/types/models/generic-id-name';
import { UserSessionHotel } from '@/types/models/user-session';
import { apiV1CrudsService } from '@/utils/api';
import { formatDateApi, getDatesArr } from '@/utils/dates';
import { DatePicker, LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function PickUpJobModal() {
  const navigate = useNavigate();
  const { showSuccess, showError } = useNotification();
  const { hotels } = useAppSelector((state) => state.auth.userSessionDropdowns);
  const [submiting, setSubmiting] = useState(false);
  const { modalOpened, hotelsToImport, startDate, endDate } = useAppSelector(
    (state) => state.logs.pickUpJobs,
  );
  const [operation, setOperation] = useState(PickUpJobOperation.S3_IMPORT);

  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setPickUpJobModalOpened(false));
    dispatch(setPickUpJobHotels([]));
  };

  const onSubmit = async () => {
    try {
      if (hotelsToImport.length === 0) {
        showError('Selecione ao menos um hotel');
        return;
      }
      const dates = getDatesArr(startDate, endDate);
      const answer = window.confirm(
        `Tem certeza que deseja reprocessar ${dates.length} dias para ${hotelsToImport.length} hotéis?`,
      );
      if (!answer) return;

      setSubmiting(true);
      const params = {
        hotels: hotelsToImport.map((x) => x._id),
        startDate: formatDateApi(startDate),
        endDate: formatDateApi(endDate),
        operation,
      };

      await apiV1CrudsService.post('/pick-up-jobs', params);
      showSuccess('Importação solicitada com sucesso');
      onClose();
      dispatch(fetchPickUpJobs());
      navigate('/logs/pick-up-jobs');
    } catch {}
    setSubmiting(false);
  };

  return (
    <Dialog open={modalOpened} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle id="alert-dialog-title" sx={{ borderBottomStyle: 'dashed', pb: 2 }}>
        Reprocessamento de PickUps
      </DialogTitle>

      <DialogContent>
        <DialogContentText sx={{ my: 3 }}>
          Insira as informações abaixo para reprocessar os dados de <b>Pick-Up</b> manualmente.
        </DialogContentText>
        <Grid container spacing={3} sx={{ mb: 2 }}>
          <Grid item xs={12} md={6}>
            <DatePicker
              label="Data inicial"
              inputFormat="dd/MM/yyyy"
              value={startDate}
              onChange={(startDate) => {
                if (!startDate) return;
                dispatch(setPickUpJobStartDate(startDate));
                if (startDate > endDate) {
                  dispatch(setPickUpJobEndDate(startDate));
                }
              }}
              renderInput={(params) => <TextField size="small" fullWidth {...params} />}
              disabled={submiting}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              label="Data final"
              inputFormat="dd/MM/yyyy"
              value={endDate}
              onChange={(endDate) => {
                if (!endDate) return;
                dispatch(setPickUpJobEndDate(endDate));
                if (endDate < startDate) {
                  dispatch(setPickUpJobStartDate(endDate));
                }
              }}
              renderInput={(params) => <TextField size="small" fullWidth {...params} />}
              disabled={submiting}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <SelectStaticMultiple<UserSessionHotel>
              onChangeValues={(val) => {
                dispatch(setPickUpJobHotels(val));
              }}
              values={hotelsToImport}
              label="Hotéis"
              options={hotels}
              size="small"
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <SelectStatic
              label="Operação"
              options={allPickUpJobOperationDropdownOptions}
              value={allPickUpJobOperationDropdownOptions.find((x) => x._id === operation) || null}
              onChangeValue={function (value: GenericIdName | null): void {
                setOperation(value?._id as PickUpJobOperation);
              }}
              size="small"
              searchable={false}
              disableClearable
              helperText={getPickUpJobOperationHumanReadDescription(operation)}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <DialogContentText sx={{ mt: 2 }}>
              <b>Atenção:</b> A importação pode demorar alguns minutos, dependendo da quantidade de
              hotéis e dias selecionados.
            </DialogContentText>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Fechar
        </Button>
        <LoadingButton variant="contained" loading={submiting} onClick={onSubmit}>
          Solicitar processamento
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
