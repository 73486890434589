import Iconify from '@/components/Iconify';
import Label from '@/components/Label';
import Scrollbar from '@/components/Scrollbar';
import { HOTEL_LEGACY_DATA_ICON } from '@/config/icons';
import { defaultTableProps } from '@/config/table-config';
import { useAppDispatch, useAppSelector } from '@/redux';
import { LegacyDataSliceState } from '@/redux/slices/legacyDataSlice';
import {
  setPickUpJobEndDate,
  setPickUpJobHotels,
  setPickUpJobModalOpened,
  setPickUpJobStartDate,
} from '@/redux/slices/logSlice';
import { UserSessionHotel } from '@/types/models/user-session';
import { formatDate } from '@/utils/dates';
import { Avatar, Card, CardContent, CardHeader, Paper, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';

type GridType = LegacyDataSliceState['importedPickUpsGroupedByMonth'][0];

export default function LegacyDataPickUpMonthContainer() {
  const dispatch = useAppDispatch();
  const { loading, importedPickUpsGroupedByMonth, selectedHotel } = useAppSelector(
    (state) => state.legacyData,
  );

  const total = importedPickUpsGroupedByMonth.reduce((acc, curr) => acc + curr.count, 0);
  const lastItem = importedPickUpsGroupedByMonth[importedPickUpsGroupedByMonth.length - 1];

  const columns = useMemo<GridColDef<GridType>[]>(
    () => [
      {
        field: 'month',
        headerName: 'Mês',
        flex: 1,
        type: 'date',
        renderCell: ({ value }) => formatDate(value, 'LLLL/yyyy'),
      },
      {
        field: 'count',
        headerName: 'Dias importados',
        flex: 1,
        type: 'number',
      },
      {
        field: 'status',
        headerName: 'Dias pendentes',
        sortable: false,
        flex: 1,
        type: 'number',
        valueGetter: ({ row }) => {
          const month = new Date(row.month);
          const monthDurationInDays = new Date(
            month.getFullYear(),
            month.getMonth() + 1,
            0,
          ).getDate();
          return monthDurationInDays - row.count;
        },
        renderCell: ({ value, row }) =>
          value > 0 ? (
            <Tooltip
              arrow
              title={`${value} dias não foram processados. Clique para reprocessar.`}
              placement="left"
            >
              <span
                className="pointer"
                onClick={() => {
                  dispatch(setPickUpJobHotels([selectedHotel as UserSessionHotel]));
                  dispatch(setPickUpJobStartDate(new Date(row.month)));
                  dispatch(setPickUpJobEndDate(new Date(row.month)));
                  dispatch(setPickUpJobModalOpened(true));
                }}
              >
                <Label color="error">{value}</Label>
              </span>
            </Tooltip>
          ) : (
            <Label color="success">{value}</Label>
          ),
      },
    ],
    [dispatch, selectedHotel],
  );

  return (
    <Card elevation={3} sx={{ py: 1, mt: 3 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'primary' }} aria-label="recipe">
            <Iconify icon={HOTEL_LEGACY_DATA_ICON} width={25} height={25} />
          </Avatar>
        }
        title="Histórico de importação (IO via Scraping) de pick-ups - Resumo por mês"
        subheader={'Estes são os dados necessários para cálculo de metas de emissão (LY)'}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', paddingBottom: 2 }}
      />
      <CardContent>
        <Paper sx={{ width: '100%' }}>
          <p>
            <b>Total:</b> {total} dias importados do IO
          </p>
          {!!lastItem?.month ? (
            <p>
              <b>Último mês importado:</b> {formatDate(lastItem.month, 'LLLL/yyyy')}
            </p>
          ) : null}
          <br />
          <Scrollbar sx={{ width: 'auto' }}>
            <DataGrid<GridType>
              {...defaultTableProps}
              paginationMode="client"
              sortingMode="client"
              rows={importedPickUpsGroupedByMonth}
              columns={columns}
              loading={loading}
              getRowId={(row) => row.month.toString()}
            />
          </Scrollbar>
        </Paper>
      </CardContent>
    </Card>
  );
}
