export enum DashboardConcurrencyStatus {
  OK = 'OK',
  BELOW = 'BELOW',
  ABOVE = 'ABOVE',
  UNAVAILABLE = 'UNAVAILABLE',
  NOT_IMPORTED = 'NOT_IMPORTED',
  ERROR = 'ERROR',
}

export function getDashboardConcurrencyStatusHumanReadable(status: DashboardConcurrencyStatus) {
  switch (status) {
    case DashboardConcurrencyStatus.NOT_IMPORTED:
      return 'Não importado';
    case DashboardConcurrencyStatus.ERROR:
      return 'Erro';
    case DashboardConcurrencyStatus.OK:
      return 'OK';
    case DashboardConcurrencyStatus.ABOVE:
      return 'Acima';
    case DashboardConcurrencyStatus.BELOW:
      return 'Abaixo';
    case DashboardConcurrencyStatus.UNAVAILABLE:
      return 'Indisponível';
    default:
      return status;
  }
}
