import Iconify from '@/components/Iconify';
import { APP_LOADING_ICON, APP_SYNC_ICON } from '@/config/icons';
import { useNotification } from '@/hooks/useNotification';
import { useAppSelector } from '@/redux';
import { ConcurrentHotelAvailability } from '@/types/dashboards/concurrency';
import { ConcurrentHotel } from '@/types/models/concurrent-hotel';
import { apiV1ConcurrencyService } from '@/utils/api';
import { formatDate, formatDateApi } from '@/utils/dates';
import { Box, Button } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import ConcurrencyDateDetailsTableModal from './ConcurrencyDateDetailsTableModal';

interface Props {
  dateSelected: Date | null;
}

export default function ConcurrencyDateDetailsContainer({ dateSelected }: Props) {
  const [updatingAll, setUpdatingAll] = useState(false);
  const { showSuccess, showWarning } = useNotification();
  const [data, setData] = useState<ConcurrentHotelAvailability[]>([]);
  const [loading, setLoading] = useState(true);
  const [hotelsLoadingManualData, setHotelsLoadingManualData] = useState<Record<string, boolean>>(
    {},
  );
  const { channel, concurrentHotels } = useAppSelector((state) => state.filters.concurrencyFilters);

  const getData = useCallback(async () => {
    try {
      if (!dateSelected) return;
      setLoading(true);
      const params = {
        channel,
        concurrentHotels: concurrentHotels.map((x) => x._id),
        startDate: formatDateApi(dateSelected),
        endDate: formatDateApi(dateSelected),
      };
      const { data } = await apiV1ConcurrencyService.get(`/dashboard/details`, { params });
      setData(data);
    } catch {
      //
    }
    setLoading(false);
  }, [dateSelected, channel, concurrentHotels]);

  const onManualImport = useCallback(
    async (concurrentHotel: Partial<ConcurrentHotel>) => {
      const concurrentHotelId = concurrentHotel._id!;
      try {
        if (!dateSelected) return;
        showWarning(`Iniciando importação do hotel ${concurrentHotel.name}`);
        setHotelsLoadingManualData((prev) => ({ ...prev, [concurrentHotelId]: true }));
        const params = {
          date: formatDateApi(dateSelected),
          channel,
        };
        await apiV1ConcurrencyService.post(
          `/concurrent-hotel-availabilities/${concurrentHotelId}`,
          params,
        );
        showSuccess(`Importação do hotel ${concurrentHotel.name} concluída com sucesso!`);
      } catch {
        //
      }
      setHotelsLoadingManualData((prev) => ({ ...prev, [concurrentHotelId]: false }));
    },
    [channel, dateSelected, showSuccess, showWarning],
  );

  useEffect(() => {
    if (!!dateSelected && !Object.values(hotelsLoadingManualData).some((x) => x === true)) {
      getData();
    }
  }, [dateSelected, getData, hotelsLoadingManualData]);

  useEffect(() => {
    if (!dateSelected) {
      setData([]);
    }
  }, [dateSelected]);

  const updateAll = async () => {
    try {
      setUpdatingAll(true);
      await Promise.all(data.map((x) => onManualImport(x.concurrentHotel)));
    } catch {}
    setUpdatingAll(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 800,
        justifyContent: 'center',
      }}
    >
      <Button
        endIcon={<Iconify icon={updatingAll ? APP_LOADING_ICON : APP_SYNC_ICON} />}
        disabled={updatingAll || loading}
        sx={{ maxWidth: 500, mb: 2, textTransform: 'none' }}
        variant="contained"
        onClick={updateAll}
      >
        {updatingAll
          ? 'Atualizando...'
          : `Atualizar tarifas via Booking.com para o dia ${formatDate(
              dateSelected,
              'dd/MM/yyyy',
            )}`}
      </Button>
      <ConcurrencyDateDetailsTableModal
        onManualImport={onManualImport}
        hotelsLoadingManualData={hotelsLoadingManualData}
        loading={loading}
        data={data}
      />
    </Box>
  );
}
