import { useAppDispatch, useAppSelector } from '@/redux';
import { setLegacyDataHotel } from '@/redux/slices/legacyDataSlice';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab } from '@mui/material';
import { useState } from 'react';
import LegacyDataPickUpDayContainer from './LegacyDataPickUpDayContainer';
import LegacyDataPickUpMonthContainer from './LegacyDataPickUpMonthContainer';

export default function HotelLegacyDataModal() {
  const [activeTab, setActiveTab] = useState<'1' | '2' | '3' | '4'>('1');
  const { selectedHotel } = useAppSelector((state) => state.legacyData);

  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setLegacyDataHotel(null));
    setActiveTab('1');
  };

  if (!selectedHotel?._id) {
    return null;
  }

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle id="alert-dialog-title" sx={{ borderBottomStyle: 'dashed', pb: 2 }}>
        Detalhes importação PICK-UP - {selectedHotel?.name}
      </DialogTitle>

      <DialogContent>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={(e, val) => setActiveTab(val)}>
              <Tab label="PickUps (resumo)" value="1" />
              <Tab label="PickUps (detalhes)" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <LegacyDataPickUpMonthContainer />
          </TabPanel>
          <TabPanel value="2">
            <LegacyDataPickUpDayContainer />
          </TabPanel>
        </TabContext>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
