export function getNameInitials(name: string) {
  try {
    const splited = name.split(' ');
    const initials =
      splited.length > 1 ? splited[0].charAt(0) + splited[1].charAt(0) : splited[0].charAt(0);
    return initials.toUpperCase();
  } catch (error) {
    console.log(`getUserInitials LOG:  error`, error);
    return name;
  }
}

export function removeSpecialChars(str: string, removeWhiteSpacesToo = false): string {
  if (!str) return '';
  if (removeWhiteSpacesToo) {
    return str.replace(/[^\w\s]/gi, '').replace(/ /, '');
  }
  return str.replace(/[^\w\s]/gi, '');
}

export function reduceString(str: string, maxLength: number) {
  try {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  } catch (error) {
    console.log(`reduceString LOG:  error:`, error);
    return str;
  }
}

export const removeDuplicatedItems = <T>(data: T[], key?: keyof T) => {
  if (!key) return [...new Set(data)];
  return [
    ...data.filter((value, index, self) => index === self.findIndex((t) => t[key] === value[key])),
  ];
};

export function groupByKey<T>(data: T[], key: keyof T, toUpperCase = false): Record<keyof T, T[]> {
  if (toUpperCase) {
    return data.reduce(function (prev, x) {
      (prev[(x[key] as any)?.toUpperCase()] = prev[(x[key] as any)?.toUpperCase()] || []).push(x);
      return prev;
    }, {} as any);
  }

  return data.reduce(function (prev, x) {
    (prev[x[key]] = prev[x[key]] || []).push(x);
    return prev;
  }, {} as any);
}

export function getDomainFromUrl(url: string) {
  try {
    return new URL(url).hostname;
  } catch (error) {
    console.log(`getDomainFromUrl LOG:  error`, error);
    return '';
  }
}
