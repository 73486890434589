import Iconify from '@/components/Iconify';
import { RMS_ICON } from '@/config/icons';
import { Fluctuation } from '@/types/models/fluctuation';
import { formatDate } from '@/utils/dates';
import { Avatar, Card, CardContent, CardHeader, Paper } from '@mui/material';
import FluctuationIndexes from './FluctuationIndexes';

interface Props {
  fluctuation: Fluctuation;
}

export default function FluctuationIndexesContainer({ fluctuation }: Props) {
  return (
    <Card elevation={3} sx={{ py: 1, mt: 3 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'primary' }} aria-label="recipe">
            <Iconify icon={RMS_ICON} width={30} height={30} />
          </Avatar>
        }
        title="Índices do dia"
        subheader={`Veja abaixo os índices do dia ${formatDate(
          fluctuation?.date,
          'dd/MM/yyyy (EEEE)'
        )}.`}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', paddingBottom: 2 }}
      />
      <CardContent>
        <Paper sx={{ width: '100%' }}>
          <FluctuationIndexes fluctuation={fluctuation} />
        </Paper>
      </CardContent>
    </Card>
  );
}
