import ConcurrencyDateDetailsContainer from '@/components/dashboards/concurrency/ConcurrencyDateDetailsContainer';
import { useAppDispatch, useAppSelector } from '@/redux';
import { updateDashConcurrencyDateSelected } from '@/redux/slices/dashboards/dashConcurrencySlice';
import { getConcurrentChannelHumanRead } from '@/types/enums/dashboards/concurrency/dash-concurrent-channel.enum';
import { formatDate } from '@/utils/dates';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useCallback } from 'react';

export default function ConcurrencyDateDetailsModal() {
  const dispatch = useAppDispatch();
  const { dateSelected } = useAppSelector((state) => state.dashConcurrency);
  const { channel } = useAppSelector((state) => state.filters.concurrencyFilters);

  const onClose = useCallback(() => {
    dispatch(updateDashConcurrencyDateSelected(null));
  }, [dispatch]);

  return (
    <Dialog
      open={!!dateSelected}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={false}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        Detalhes Concorrência - {formatDate(dateSelected, 'dd/MM/yyyy (EEEE)')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ my: 2 }} id="alert-dialog-description">
          Canal: {getConcurrentChannelHumanRead(channel)}
        </DialogContentText>
        <ConcurrencyDateDetailsContainer dateSelected={dateSelected} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}
