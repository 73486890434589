import { useAppDispatch, useAppSelector } from '@/redux';
import { setFluctuationLogDetailsOpened } from '@/redux/slices/rmsSlice';
import { formatDate } from '@/utils/dates';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import FluctuationLogDetailsContainer from './FluctuationLogDetailsContainer';

export default function FluctuationLogDetailsModal() {
  const { fluctuationLogDetailsModalOpened } = useAppSelector((state) => state.rms);

  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setFluctuationLogDetailsOpened(null));
  };

  return (
    <Dialog
      open={!!fluctuationLogDetailsModalOpened?._id}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title" sx={{ borderBottomStyle: 'dashed', pb: 2 }}>
        Log de integração Omnibees -{' '}
        {formatDate(fluctuationLogDetailsModalOpened?.date, 'dd/MM/yyyy (EEEE)')}
      </DialogTitle>

      <DialogContent>
        {!!fluctuationLogDetailsModalOpened?._id ? (
          <FluctuationLogDetailsContainer fluctuationLog={fluctuationLogDetailsModalOpened} />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
