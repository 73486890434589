import { DashPickUpMenu, DashPickUpType } from '@/types/dashboards/pick-up-old';
import { DashIndex } from '@/types/enums/dashboards/dash-index.enum';
import { CHANNEL_ICON, HOTEL_ICON, SEGMENT_ICON } from '../icons';

export const PICK_UP_MENUS: Record<DashPickUpType, DashPickUpMenu> = {
  hotels: {
    type: DashPickUpType.HOTELS,
    label: 'Pick-Up Hoteis',
    enabledIndexes: [
      DashIndex.REVENUE,
      DashIndex.DAILY_AVERAGE,
      DashIndex.ROOM_NIGHT,
      DashIndex.REV_PAR,
      DashIndex.OCCUPATION,
    ],
    icon: HOTEL_ICON,
  },
  segments: {
    type: DashPickUpType.SEGMENTS,
    label: 'Pick-Up Segmentos',
    enabledIndexes: [DashIndex.REVENUE, DashIndex.DAILY_AVERAGE, DashIndex.ROOM_NIGHT],
    icon: SEGMENT_ICON,
  },
  channels: {
    type: DashPickUpType.CHANNELS,
    label: 'Pick-Up Canais',
    enabledIndexes: [DashIndex.REVENUE, DashIndex.DAILY_AVERAGE, DashIndex.ROOM_NIGHT],
    icon: CHANNEL_ICON,
  },
};
