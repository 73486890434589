import NotistackProvider from '@/components/NotistackProvider';
import { ProgressBarStyle } from '@/components/ProgressBar';
import ScrollToTop from '@/components/ScrollToTop';
import LoadingScreen from '@/components/loadings/LoadingScreen';
import ThemeSettings from '@/contexts/settings';
import ThemeProvider from '@/contexts/theme';
import { useAppDispatch, useAppSelector } from '@/redux';
import { fetchProfileData, setAppLoaded } from '@/redux/slices/authSlice';
import PrivateRoutes from '@/routes/PrivateRoutes';
import PublicRoutes from '@/routes/PublicRoutes';
import { LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { useEffect } from 'react';
import MotionLazyContext from './contexts/MotionLazyContext';

export default function App() {
  const { isLogged, bootingApp } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const checkStoredToken = async () => {
      const token = localStorage.getItem('@token');
      if (token) {
        // Reuse existing token if available
        await dispatch(fetchProfileData());
      }
      dispatch(setAppLoaded());
    };
    checkStoredToken();
  }, [dispatch]);

  return (
    <MotionLazyContext>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
          <ThemeSettings>
            <NotistackProvider>
              <ProgressBarStyle />
              <ScrollToTop />
              {bootingApp ? <LoadingScreen /> : isLogged ? <PrivateRoutes /> : <PublicRoutes />}
            </NotistackProvider>
          </ThemeSettings>
        </LocalizationProvider>
      </ThemeProvider>
    </MotionLazyContext>
  );
}
