export enum ConcurrentChannel {
  BOOKING = 'BOOKING',
}

export function getConcurrentChannelHumanRead(value: ConcurrentChannel) {
  switch (value) {
    case ConcurrentChannel.BOOKING:
      return 'Booking.com';
    default:
      return value;
  }
}
