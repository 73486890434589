import { Paper } from '@mui/material';
import AppVersionLabel from './AppVersionLabel';

export default function AppVersionDisclaimer() {
  return (
    <Paper
      sx={{
        backgroundColor: 'transparent',
        height: '30px', // Ajuste a altura conforme necessário
        width: '100%',
        position: 'fixed',
        top: 0,
        zIndex: -1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <AppVersionLabel />
    </Paper>
  );
}
