import SelectStaticMultiple from '@/components/forms/select/SelectStaticMultiple';
import { useNotification } from '@/hooks/useNotification';
import { useAppDispatch, useAppSelector } from '@/redux';
import {
  fetchRevenueJobs,
  setRevenueJobEndDate,
  setRevenueJobHotels,
  setRevenueJobModalOpened,
  setRevenueJobStartDate,
} from '@/redux/slices/logSlice';
import { UserSessionHotel } from '@/types/models/user-session';
import { apiV1CrudsService } from '@/utils/api';
import { formatDateApi, getDatesArr } from '@/utils/dates';
import { DatePicker, LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function RevenueJobModal() {
  const navigate = useNavigate();
  const { showSuccess, showError } = useNotification();
  const { hotels } = useAppSelector((state) => state.auth.userSessionDropdowns);
  const [submiting, setSubmiting] = useState(false);
  const { modalOpened, hotelsToImport, startDate, endDate } = useAppSelector(
    (state) => state.logs.revenueJobs,
  );

  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setRevenueJobModalOpened(false));
    dispatch(setRevenueJobHotels([]));
  };

  const onSubmit = async () => {
    try {
      if (hotelsToImport.length === 0) {
        showError('Selecione ao menos um hotel');
        return;
      }
      const days = getDatesArr(startDate, endDate);
      const answer = window.confirm(
        `Tem certeza que deseja processar ${days.length} dias para ${hotelsToImport.length} hotéis?`,
      );
      if (!answer) return;

      setSubmiting(true);
      const params = {
        hotels: hotelsToImport.map((x) => x._id),
        startDate: formatDateApi(startDate),
        endDate: formatDateApi(endDate),
      };
      await apiV1CrudsService.post('/revenue-jobs', params);
      showSuccess('Importação solicitada com sucesso');
      onClose();
      dispatch(fetchRevenueJobs());
      navigate('/logs/revenue-jobs');
    } catch {}
    setSubmiting(false);
  };

  return (
    <Dialog open={modalOpened} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle id="alert-dialog-title" sx={{ borderBottomStyle: 'dashed', pb: 2 }}>
        Importação Manual (HITS)
      </DialogTitle>

      <DialogContent>
        <DialogContentText sx={{ my: 3 }}>
          Insira as informações abaixo para importar os dados manualmente do HITS.
        </DialogContentText>
        <Grid container spacing={3} sx={{ mb: 2 }}>
          <Grid item xs={12} md={6}>
            <DatePicker
              label="Data inicial"
              inputFormat="dd/MM/yyyy"
              value={startDate}
              onChange={(startDate) => {
                if (!startDate) return;
                dispatch(setRevenueJobStartDate(startDate));
                if (startDate > endDate) {
                  dispatch(setRevenueJobEndDate(startDate));
                }
              }}
              renderInput={(params) => <TextField size="small" fullWidth {...params} />}
              disabled={submiting}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              label="Data final"
              inputFormat="dd/MM/yyyy"
              value={endDate}
              onChange={(endDate) => {
                if (!endDate) return;
                dispatch(setRevenueJobEndDate(endDate));
                if (endDate < startDate) {
                  dispatch(setRevenueJobStartDate(endDate));
                }
              }}
              renderInput={(params) => <TextField size="small" fullWidth {...params} />}
              disabled={submiting}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <SelectStaticMultiple<UserSessionHotel>
              onChangeValues={(val) => {
                dispatch(setRevenueJobHotels(val));
              }}
              values={hotelsToImport}
              label="Hotéis"
              options={hotels}
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <DialogContentText sx={{ mt: 2 }}>
              <b>Atenção:</b> A importação pode demorar alguns minutos, dependendo da quantidade de
              hotéis e meses selecionados.
            </DialogContentText>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Fechar
        </Button>
        <LoadingButton variant="contained" loading={submiting} onClick={onSubmit}>
          Solicitar processamento
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
