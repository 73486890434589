import { GenericIdName } from '@/types/models/generic-id-name';
import { useFormContext } from 'react-hook-form';
import SelectStatic, { SelectStaticProps } from '../select/SelectStatic';

interface Props<T extends GenericIdName>
  extends Omit<SelectStaticProps<T>, 'value' | 'onChangeValue'> {
  name: string;
}

export default function RHFSelectStatic<T extends GenericIdName>({ name, ...props }: Props<T>) {
  const { watch, setValue, formState, clearErrors } = useFormContext();

  const value = watch(name);
  const selectedValue = props.options.find((x) => x._id === value) || null;
  const error: string | undefined = formState.errors[name]?.message;

  const onChangeValue = (values: GenericIdName | null) => {
    setValue(name, values?._id || '', { shouldDirty: true });
    clearErrors(name);
  };

  return (
    <SelectStatic {...props} onChangeValue={onChangeValue} error={error} value={selectedValue} />
  );
}
