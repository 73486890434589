/* eslint-disable import/no-duplicates */
import { DashGranularity } from '@/types/enums/dashboards/dash-granularity.enum';
import { GenericIdName } from '@/types/models/generic-id-name';
import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  formatDistanceToNow,
  formatISO,
  getTime,
  isSameMonth,
  parseISO,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function formatTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export type FormatDatevalidFormats =
  | 'dd'
  | 'dd/MM/yyyy - HH:mm'
  | 'dd/MM/yyyy - HH:mm:ss'
  | 'dd/MM/yyyy'
  | 'dd/MM/yyyy (EEEEEE)' // 01/01/2021 (seg):
  | 'dd/MM/yyyy (EE)' // 01/01/2021 (segunda):
  | 'dd/MM/yyyy (EEEE)' // 01/01/2021 (segunda-feira):
  | 'dd/MM'
  | 'yyyy/MM/dd'
  | 'yyyy-MM-dd'
  | 'dd-MM-yyyy'
  | 'MM/yyyy'
  | 'MMM/yyyy'
  | 'M' // Número do m
  | 'yyyy'
  | 'EEE' // Segunda, Terça, Quarta, etc
  | 'EEEE' // Segunda-feira, terça-feira, etc
  | 'EEEEEE' // Seg, Ter, Qua, etc
  | 'LLLL' // Janeiro, fevereiro, etc
  | 'LLLL/yyyy' // Janeiro/2022, fevereiro/2022, etc
  | 'LLL/yyyy'; // mar/2022, abr/2022, etc

export function isValidDate(date: unknown) {
  return date instanceof Date && !isNaN(date.getTime());
}

const timezone = getUserTimezone();
export function formatDate(date: any, formatString: FormatDatevalidFormats = 'dd/MM/yyyy - HH:mm') {
  try {
    if (!date) return date;
    if (timezone === 'America/Sao_Paulo') {
      const apiDate = new Date(date);
      const utcDate = formatString.includes('HH')
        ? apiDate.toISOString()
        : apiDate.toISOString().split('T')[0];
      return format(parseISO(utcDate), formatString, { locale: ptBR });
    }
    // Converte para UTC
    if (typeof date === 'string' && date.includes('-') && !formatString.includes('HH')) {
      let offset: any = getUserTimezoneOffsetInHours();
      if (offset < 0) offset = Math.abs(offset);
      offset = offset.toString().padStart(2, '0');
      date = `${date.split('T')[0]}T00:00:00.000-${offset}:00`;
    }
    return format(new Date(date), formatString, { locale: ptBR });
  } catch {
    return date;
  }
}

/**
 * Return a date in YYYY-MM-DD format
 * @param date Date
 * @returns string
 */
export function formatDateApi(date: Date | string): string {
  let _date = new Date(date);
  const offset = _date.getTimezoneOffset();
  _date = new Date(_date.getTime() - offset * 60 * 1000);
  return _date.toISOString().split('T')[0];
}

export function formatToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function getRangeHumanRead(
  startDate: Date | string,
  endDate: Date | string,
  granularity = DashGranularity.DAY,
) {
  try {
    if (!startDate || !endDate) return '-';
    return `${formatDateByGranularity(startDate, granularity)} a ${formatDateByGranularity(
      endDate,
      granularity,
    )}`;
  } catch (error) {
    console.log('getRangeHumanRead > error', error);
    return startDate || endDate;
  }
}

export function getDatesArr(startDate: Date, endDate: Date, groupByMonth = false): Date[] {
  if (endDate < startDate) {
    return [startDate];
  }
  const dateArray: Date[] = [];
  const currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  if (groupByMonth) {
    return dateArray.reduce((prev, actual) => {
      if (prev.some((x) => isSameMonth(x, actual))) {
        return prev;
      }
      return [...prev, new Date(actual.getFullYear(), actual.getMonth())];
    }, [] as Date[]);
  }
  return dateArray;
}

export function isSameDay(d1: Date, d2: Date) {
  return d1.toDateString() === d2.toDateString();
}

/**
 * Retorna uma matriz as semanas e respectivos dias
 * @param startDate
 * @returns
 */
export function getCalendarDays(startDate: Date) {
  const monthStart = startOfMonth(startDate);
  const monthEnd = endOfMonth(monthStart);
  const start = startOfWeek(monthStart);
  const end = endOfWeek(monthEnd);

  const rows: Date[][] = [];
  const days = [];
  let day = start;
  while (day <= end) {
    for (let i = 0; i < 7; i++) {
      days.push(day);
      day = addDays(day, 1);
    }
    rows.push([...days]);
    days.length = 0;
  }
  return rows;
}

export function sleep(time = 3000) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), time);
  });
}

export const serializeTimestamp = (timestamp: Date) => {
  try {
    return formatISO(timestamp);
  } catch (error) {
    console.log(`serializeTimestamp LOG:  error:`, error);
    return timestamp as any;
  }
};

export const deserializeTimestamp = (timestamp: string) => {
  try {
    return parseISO(timestamp);
  } catch (error) {
    console.log(`deserializeTimestamp LOG:  error:`, error);
    return timestamp as any;
  }
};

export const daysOfWeek: GenericIdName[] = [
  { _id: '0', name: 'Domingo' },
  { _id: '1', name: 'Segunda' },
  { _id: '2', name: 'Terça' },
  { _id: '3', name: 'Quarta' },
  { _id: '4', name: 'Quinta' },
  { _id: '5', name: 'Sexta' },
  { _id: '6', name: 'Sábado' },
];

export const formatCountDown = (seconds: number): string => {
  try {
    if (seconds <= 0) return '0s';
    if (seconds <= 60) return `${seconds}s`;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // Format minutes and seconds to always have two digits
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  } catch (error) {
    console.log(`formatCountDown LOG:  error:`, error);
    return `${seconds}s`;
  }
};

export function groupByDate<T>(data: T[], fieldName = 'date'): Record<string, T[]> {
  return data.reduce(function (prev, x: any) {
    (prev[x[fieldName]] = prev[x[fieldName]] || []).push(x);
    return prev;
  }, {} as Record<string, T[]>);
}

export function formatDateByGranularity(date: any, granularity: DashGranularity) {
  switch (granularity) {
    case DashGranularity.DAY:
      return formatDate(date, 'dd/MM/yyyy (EEEEEE)');
    case DashGranularity.MONTH:
      return formatDate(date, 'LLL/yyyy');
    default:
      return formatDate(date);
  }
}

export function getDatesArrByGranularity(
  startDate: Date,
  endDate: Date,
  granularity: DashGranularity,
): Date[] {
  if (endDate < startDate) {
    return [startDate];
  }

  switch (granularity) {
    case DashGranularity.DAY:
      return getDatesArr(startDate, endDate);
    case DashGranularity.MONTH:
      return getDatesArr(startDate, endDate, true);
    default:
      return getDatesArr(startDate, endDate);
  }
}

export function getUserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getUserTimezoneOffsetInHours() {
  return new Date().getTimezoneOffset() / 60;
}
