import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';

export const toast = {
  error: (text = 'Não foi possível procesar sua solicitação no momento.') => {
    Toastify({
      text,
      close: true,
      duration: 3000,
      className: 'mui--align-top',
      position: 'center',
      style: {
        background: 'linear-gradient(to right, #FF4842, #FF7842)',
        fontWeight: 'bold',
      },
    }).showToast();
  },
  success: (text = 'OK!') => {
    Toastify({
      text,
      close: true,
      duration: 3000,
      position: 'center',
      style: {
        background: 'linear-gradient(to right, #00b09b, #96c93d)',
        fontWeight: 'bold',
      },
    }).showToast();
  },
};
