import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
import { GenericIdName } from '../models/generic-id-name';

export enum DatePickerPeriod {
  CUSTOM = 'CUSTOM',
  TODAY = 'TODAY',
  CURRENT_WEEK = 'CURRENT_WEEK',
  CURRENT_MONTH = 'CURRENT_MONTH',
  CURRENT_YEAR = 'CURRENT_YEAR',
  YESTERDAY = 'YESTERDAY',
  PREV_3_DAYS = 'PREV_3_DAYS',
  PREV_7_DAYS = 'PREV_7_DAYS',
  PREV_WEEK = 'PREV_WEEK',
  PREV_MONTH = 'PREV_MONTH',
  PREV_3_MONTHS = 'PREV_3_MONTHS',
  PREV_6_MONTHS = 'PREV_6_MONTHS',
  PREV_YEAR = 'PREV_YEAR',
  PREV_12_MONTHS = 'PREV_12_MONTHS',
  TOMORROW = 'TOMORROW',
  NEXT_3_DAYS = 'NEXT_3_DAYS',
  NEXT_7_DAYS = 'NEXT_7_DAYS',
  NEXT_15_DAYS = 'NEXT_15_DAYS',
  NEXT_30_DAYS = 'NEXT_30_DAYS',
  NEXT_WEEK = 'NEXT_WEEK',
  NEXT_MONTH = 'NEXT_MONTH',
  NEXT_YEAR = 'NEXT_YEAR',
  NEXT_3_MONTHS = 'NEXT_3_MONTHS',
  NEXT_6_MONTHS = 'NEXT_6_MONTHS',
  NEXT_12_MONTHS = 'NEXT_12_MONTHS',
}

export const getDatePickerPeriodHumanRead = (period: DatePickerPeriod) => {
  switch (period) {
    case DatePickerPeriod.CURRENT_WEEK:
      return 'Semana atual';
    case DatePickerPeriod.NEXT_WEEK:
      return 'Próxima semana';
    case DatePickerPeriod.PREV_WEEK:
      return 'Semana anterior';
    case DatePickerPeriod.CURRENT_MONTH:
      return 'Mês atual';
    case DatePickerPeriod.NEXT_MONTH:
      return 'Próximo mês';
    case DatePickerPeriod.PREV_MONTH:
      return 'Mês anterior';
    case DatePickerPeriod.CURRENT_YEAR:
      return 'Ano atual';
    case DatePickerPeriod.NEXT_YEAR:
      return 'Próximo ano';
    case DatePickerPeriod.PREV_YEAR:
      return 'Ano anterior';
    case DatePickerPeriod.NEXT_3_MONTHS:
      return 'Próximos 3 meses';
    case DatePickerPeriod.PREV_3_MONTHS:
      return 'Últimos 3 meses';
    case DatePickerPeriod.NEXT_6_MONTHS:
      return 'Próximos 6 meses';
    case DatePickerPeriod.PREV_6_MONTHS:
      return 'Últimos 6 meses';
    case DatePickerPeriod.NEXT_12_MONTHS:
      return 'Próximos 12 meses';
    case DatePickerPeriod.PREV_12_MONTHS:
      return 'Últimos 12 meses';
    case DatePickerPeriod.NEXT_7_DAYS:
      return 'Próximos 7 dias';
    case DatePickerPeriod.NEXT_15_DAYS:
      return 'Próximos 15 dias';
    case DatePickerPeriod.NEXT_30_DAYS:
      return 'Próximos 30 dias';
    case DatePickerPeriod.TODAY:
      return 'Hoje';
    case DatePickerPeriod.YESTERDAY:
      return 'Ontem';
    case DatePickerPeriod.TOMORROW:
      return 'Amanhã';
    case DatePickerPeriod.PREV_3_DAYS:
      return 'Últimos 3 dias';
    case DatePickerPeriod.PREV_7_DAYS:
      return 'Últimos 7 dias';
    case DatePickerPeriod.CUSTOM:
      return 'Período customizado';
    case DatePickerPeriod.NEXT_3_DAYS:
      return 'Próximos 3 dias';
    default:
      return period;
  }
};

export function getRangeFromDatepickerPeriod(period: DatePickerPeriod): [Date, Date] {
  const today = new Date();
  let startDate = today;
  let endDate = today;
  switch (period) {
    case DatePickerPeriod.CURRENT_WEEK:
      startDate = startOfWeek(today);
      endDate = startOfDay(endOfWeek(today));
      break;
    case DatePickerPeriod.NEXT_WEEK:
      const nextWeek = addWeeks(today, 1);
      startDate = startOfWeek(nextWeek);
      endDate = startOfDay(endOfWeek(nextWeek));
      break;
    case DatePickerPeriod.PREV_WEEK:
      const prevWeek = subWeeks(today, 1);
      startDate = startOfWeek(prevWeek);
      endDate = startOfDay(endOfWeek(prevWeek));
      break;
    case DatePickerPeriod.CURRENT_MONTH:
      startDate = startOfMonth(today);
      endDate = startOfDay(endOfMonth(today));
      break;
    case DatePickerPeriod.NEXT_MONTH:
      const nextMonth = addMonths(today, 1);
      startDate = startOfMonth(nextMonth);
      endDate = startOfDay(endOfMonth(nextMonth));
      break;
    case DatePickerPeriod.PREV_MONTH:
      const prevMonth = subMonths(today, 1);
      startDate = startOfMonth(prevMonth);
      endDate = startOfDay(endOfMonth(prevMonth));
      break;
    case DatePickerPeriod.CURRENT_YEAR:
      startDate = startOfYear(today);
      endDate = startOfDay(endOfYear(today));
      break;
    case DatePickerPeriod.NEXT_YEAR:
      const nextYear = addYears(today, 1);
      startDate = startOfYear(nextYear);
      endDate = startOfDay(endOfYear(nextYear));
      break;
    case DatePickerPeriod.PREV_YEAR:
      const prevYear = subYears(today, 1);
      startDate = startOfYear(prevYear);
      endDate = startOfDay(endOfYear(prevYear));
      break;
    case DatePickerPeriod.NEXT_3_MONTHS:
      startDate = startOfMonth(today);
      endDate = startOfDay(endOfMonth(addMonths(startDate, 2)));
      break;
    case DatePickerPeriod.PREV_3_MONTHS:
      startDate = startOfMonth(subMonths(today, 3));
      endDate = startOfDay(endOfMonth(today));
      break;
    case DatePickerPeriod.NEXT_6_MONTHS:
      startDate = startOfMonth(today);
      endDate = addMonths(startDate, 5);
      endDate = startOfDay(endOfMonth(addMonths(startDate, 5)));
      break;
    case DatePickerPeriod.PREV_6_MONTHS:
      startDate = startOfMonth(subMonths(today, 6));
      endDate = startOfDay(endOfMonth(today));
      break;
    case DatePickerPeriod.NEXT_12_MONTHS:
      startDate = startOfMonth(today);
      endDate = startOfDay(endOfMonth(addMonths(startDate, 11)));
      break;
    case DatePickerPeriod.NEXT_3_DAYS:
      startDate = startOfDay(today);
      endDate = startOfDay(addDays(today, 2));
      break;
    case DatePickerPeriod.NEXT_7_DAYS:
      startDate = startOfDay(today);
      endDate = startOfDay(addDays(startDate, 6));
      break;
    case DatePickerPeriod.NEXT_15_DAYS:
      startDate = startOfDay(today);
      endDate = startOfDay(addDays(startDate, 14));
      break;
    case DatePickerPeriod.NEXT_30_DAYS:
      startDate = startOfDay(today);
      endDate = startOfDay(addDays(startDate, 29));
      break;
    case DatePickerPeriod.PREV_12_MONTHS:
      startDate = startOfMonth(subMonths(today, 12));
      endDate = startOfDay(endOfMonth(today));
      break;
    case DatePickerPeriod.TODAY:
      startDate = startOfDay(today);
      endDate = startOfDay(today);
      break;
    case DatePickerPeriod.YESTERDAY:
      startDate = startOfDay(subDays(today, 1));
      endDate = startOfDay(subDays(today, 1));
      break;
    case DatePickerPeriod.TOMORROW:
      startDate = startOfDay(addDays(today, 1));
      endDate = startOfDay(addDays(today, 1));
      break;
    case DatePickerPeriod.PREV_3_DAYS:
      startDate = startOfDay(subDays(today, 3));
      endDate = today;
      break;
    case DatePickerPeriod.PREV_7_DAYS:
      startDate = startOfDay(subDays(today, 7));
      endDate = today;
      break;
    case DatePickerPeriod.CUSTOM:
    default:
      break;
  }

  return [startDate, endDate];
}

export function getOnlyFuturePeriods(): DatePickerPeriod[] {
  return [
    DatePickerPeriod.CURRENT_WEEK,
    DatePickerPeriod.CURRENT_MONTH,
    DatePickerPeriod.CURRENT_YEAR,
    DatePickerPeriod.NEXT_WEEK,
    DatePickerPeriod.NEXT_3_DAYS,
    DatePickerPeriod.NEXT_7_DAYS,
    DatePickerPeriod.NEXT_15_DAYS,
    DatePickerPeriod.NEXT_30_DAYS,
    DatePickerPeriod.NEXT_MONTH,
    DatePickerPeriod.NEXT_3_MONTHS,
    DatePickerPeriod.NEXT_6_MONTHS,
    DatePickerPeriod.NEXT_12_MONTHS,
    DatePickerPeriod.NEXT_YEAR,
    DatePickerPeriod.TODAY,
    DatePickerPeriod.TOMORROW,
    DatePickerPeriod.CUSTOM,
  ];
}

export function getOnlyPastPeriods(): DatePickerPeriod[] {
  return [
    DatePickerPeriod.TODAY,
    DatePickerPeriod.CURRENT_MONTH,
    DatePickerPeriod.CURRENT_WEEK,
    DatePickerPeriod.CURRENT_YEAR,
    DatePickerPeriod.YESTERDAY,
    DatePickerPeriod.PREV_3_DAYS,
    DatePickerPeriod.PREV_7_DAYS,
    DatePickerPeriod.PREV_WEEK,
    // DatePickerPeriod.CURRENT_WEEK,
    DatePickerPeriod.PREV_MONTH,
    // DatePickerPeriod.CURRENT_YEAR,
    DatePickerPeriod.PREV_3_MONTHS,
    DatePickerPeriod.PREV_6_MONTHS,
    // DatePickerPeriod.CURRENT_MONTH,
    DatePickerPeriod.PREV_12_MONTHS,
    DatePickerPeriod.PREV_YEAR,
    DatePickerPeriod.CUSTOM,
  ];
}

export const allDatePickerPeriods: GenericIdName[] = Object.values(DatePickerPeriod).map((x) => ({
  _id: x,
  name: getDatePickerPeriodHumanRead(x),
}));

export const allFuturePeriods: GenericIdName[] = getOnlyFuturePeriods().map((x) => ({
  _id: x,
  name: getDatePickerPeriodHumanRead(x),
}));

export const allPastPeriods: GenericIdName[] = getOnlyPastPeriods().map((x) => ({
  _id: x,
  name: getDatePickerPeriodHumanRead(x),
}));
