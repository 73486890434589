import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type IProps = {
  name: string;
  prefix?: string;
  suffix?: string | React.ReactNode;
};

type Props = IProps & TextFieldProps;

export default function RHFTextField({ name, prefix, suffix, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          {...other}
          inputProps={{
            step: 'any',
            ...other.inputProps,
          }}
          InputProps={{
            // step: 'any' as any,
            // step: 1,
            startAdornment: !!prefix ? (
              <InputAdornment position="start">{prefix}</InputAdornment>
            ) : null,
            endAdornment: !!suffix ? (
              <InputAdornment position="end">{suffix}</InputAdornment>
            ) : null,
            onWheel: (e: any) => e.target.blur(), // Disable changing values via mouse scroll
          }}
        />
      )}
    />
  );
}
