import { ALL_HOTELS_ICON, CITY_ICON, HOTEL_ICON, REGIONAL_ICON, WALLET_ICON } from '@/config/icons';
import { UserSession } from '../models/user-session';

export enum UserSessionMode {
  ALL = 'ALL',
  HOTEL = 'HOTEL',
  REGIONAL = 'REGIONAL',
  CITY = 'CITY',
  WALLET = 'WALLET',
}

export const getUserSessionModeHumaRead = (mode: UserSessionMode) => {
  switch (mode) {
    case UserSessionMode.ALL:
      return 'Toda a rede';
    case UserSessionMode.REGIONAL:
      return 'Regional';
    case UserSessionMode.HOTEL:
      return 'Hotel';
    case UserSessionMode.CITY:
      return 'Cidade';
    case UserSessionMode.WALLET:
      return 'Carteiras';
    default:
      return mode;
  }
};

export const getIconByUserSessionModel = (mode: UserSessionMode) => {
  switch (mode) {
    case UserSessionMode.ALL:
      return ALL_HOTELS_ICON;
    case UserSessionMode.REGIONAL:
      return REGIONAL_ICON;
    case UserSessionMode.HOTEL:
      return HOTEL_ICON;
    case UserSessionMode.CITY:
      return CITY_ICON;
    case UserSessionMode.WALLET:
      return WALLET_ICON;
    default:
      return HOTEL_ICON;
  }
};
export const getSessionQuantityText = (session: UserSession) => {
  const { mode, hotels, regionals, cities, wallets } = session;

  if (mode === UserSessionMode.REGIONAL) {
    const quantity = regionals.length;
    if (quantity === 1) {
      return regionals[0].name;
    }
    return `${quantity} regionais`;
  }

  if (mode === UserSessionMode.CITY) {
    const quantity = cities.length;
    if (quantity === 1) {
      return cities[0].name;
    }
    return `${quantity} cidades`;
  }

  if (mode === UserSessionMode.WALLET) {
    const quantity = wallets.length;
    if (quantity === 1) {
      return wallets[0].name;
    }
    return `${quantity} carteiras`;
  }

  const quantity = hotels.length;
  if (quantity === 1) {
    return hotels[0].name;
  }
  return `${quantity} hotéis`;
};
