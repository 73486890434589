import { GriCategory } from '@/types/models/gri-category';
import { GriData } from '@/types/rms/gri/gri';
import * as XLSX from 'xlsx';
import { formatDate } from '../dates';
import { downloadFromBlob } from '../download';
import { formatPercentage } from '../number';

export function getGriCategoryRangeHumanRead(category?: GriCategory) {
  return `${category?.name}: ${category?.initialOCC}% a ${category?.finalOCC}%`;
}

export const emptyGriCategoryColor = '#DDDDDD';

function parseGriData(row: GriData, emissionDates: string[]) {
  const ret: any = {
    Regional: row.hotel.regional?.name,
    Destino: row.hotel.city?.name,
    Ano: formatDate(row.dateOfBooking, 'yyyy'),
    DDD: formatDate(row.dateOfBooking, 'EEEEEE'),
    Mês: formatDate(row.dateOfBooking, 'M'),
    Data: formatDate(row.dateOfBooking, 'dd/MM/yyyy'),
    Pace: row.pace,
    Sigla: row.hotel.slug,
    Hotel: row.hotel.name,
  };

  emissionDates.forEach(
    (date) =>
      (ret[formatDate(date, 'dd/MM/yyyy (EEEEEE)')] = formatPercentage(row[date]?.occupation) ?? 0),
  );

  ret.Hoje = formatPercentage(row.occupation);
  ret.PickUp = formatPercentage(row.occupationEvolution);
  ret.GRI = row.category?.name;
  ret.DM = row.dailyAverage;
  ret.Eventos = row.holidays.map((holiday) => holiday.name).join(', ');

  return ret;
}

export function onDownloadGrioExcel(data: GriData[], emissionDates: string[]) {
  if (data.length === 0) {
    return alert('Não há dados para exportar');
  }

  const workbook = XLSX.utils.book_new();
  const csvData: any[] = [];
  data.forEach((row) => csvData.push(parseGriData(row, emissionDates)));

  const worksheet = XLSX.utils.json_to_sheet(csvData);

  if (csvData.length > 0) {
    const sample = csvData[0];
    const wscols = Object.values(sample).map((x: any) => ({
      wch: !!x ? x.toString().length + 10 : 20,
    }));
    worksheet['!cols'] = wscols;
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'GRI');

  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  });

  const fileName = 'gri.xlsx';
  downloadFromBlob(new Blob([excelBuffer], { type: 'application/octet-stream' }), fileName);
}
