import { LazyMotion } from 'framer-motion';
import { ReactNode } from 'react';

// eslint-disable-next-line import/extensions
const loadFeatures = () => import('../components/animate/features.js').then((res) => res.default);

interface Props {
  children: ReactNode;
}

export default function MotionLazyContext({ children }: Props) {
  return (
    <LazyMotion strict features={loadFeatures}>
      {children}
    </LazyMotion>
  );
}
