import ConcurrencyDateDetailsModal from './ConcurrencyDateDetailsModal';
import FluctuationConfigModal from './FluctuationConfigModal';
import FluctuationDetailsModal from './FluctuationDetailsModal';
import FluctuationLogDetailsModal from './FluctuationLogDetailsModal';
import HotelLegacyDataModal from './HotelLegacyDataModal';
import PickUpJobModal from './PickUpJobModal';
import RevenueJobModal from './RevenueJobModal';

export default function GlobalModals() {
  return (
    <>
      <FluctuationDetailsModal />
      <FluctuationLogDetailsModal />
      <FluctuationConfigModal />
      <PickUpJobModal />
      <ConcurrencyDateDetailsModal />
      <HotelLegacyDataModal />
      <RevenueJobModal />
    </>
  );
}
