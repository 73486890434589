import { RootState } from '@/redux';
import { DashRankingData } from '@/types/dashboards/ranking';
import { apiV1DashboardService } from '@/utils/api';
import { formatDateApi } from '@/utils/dates';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';

interface DashRankingSliceState {
  data: DashRankingData[];
  loading: boolean;
}

const initialState: DashRankingSliceState = {
  data: [],
  loading: false,
};

const dashRankingSlice = createSlice({
  name: 'dashRanking',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashRanking.pending, (state) => {
        state.loading = true;
        state.data = [];
      })
      .addCase(fetchDashRanking.fulfilled, (state, action: PayloadAction<DashRankingData[]>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDashRanking.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const fetchDashRanking = createAsyncThunk<DashRankingData[], void, { state: RootState }>(
  'ranking/fetchDashRanking',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { commonFilters } = getState().filters;

      const { startDate, groups, endDate, index, limitResults, reservationStatus } = commonFilters;

      if (groups.length === 0) {
        return [];
      }

      const params: any = {
        startDate: formatDateApi(startDate),
        endDate: formatDateApi(endDate),
        limit: limitResults,
        index,
        status: reservationStatus,
        groups,
      };

      const response = await apiV1DashboardService.get<DashRankingData[]>(
        `/rankings?${stringify(params, { arrayFormat: 'bracket' })}`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export default dashRankingSlice.reducer;
