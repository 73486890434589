import { RootState } from '@/redux';
import {
  ApiV1DashConcurrencyDailyResponse,
  ApiV1DashConcurrencyMonthlyResponse,
  ApiV1DashConcurrencyOnlineResponse,
} from '@/types/apiv1/responses/dashboards/api-v1-concurrency-responses';
import { ChartJSDataset } from '@/types/dashboards/charts/chart';
import {
  DashConcurrencyCalendarData,
  DashConcurrencyOnlineSearch,
  DashConcurrencyTableData,
  OnlineSearchTabs,
} from '@/types/dashboards/concurrency';
import { apiV1ConcurrencyService } from '@/utils/api';
import { formatDateApi } from '@/utils/dates';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';

interface DashConcurrencySliceState {
  monthly: {
    loading: boolean;
    tableData: DashConcurrencyTableData[];
    calendarData: DashConcurrencyCalendarData[];
  };
  daily: {
    loading: boolean;
    chartData: ChartJSDataset[];
  };
  online: {
    onlineSearchTabs: OnlineSearchTabs;
    onlineSearchSelected: DashConcurrencyOnlineSearch['searchId'];
  };
  dateSelected: Date | null;
  calendarRows: Date[][];
}

const defaultMonthlyData = {
  tableData: [],
  loading: false,
  calendarData: [],
};

const defaultDailyData = {
  chartData: [],
  loading: false,
};

const initialState: DashConcurrencySliceState = {
  monthly: defaultMonthlyData,
  daily: defaultDailyData,
  online: {
    onlineSearchTabs: {},
    onlineSearchSelected: '',
  },
  dateSelected: null,
  calendarRows: [],
};

const dashConcurrencySlice = createSlice({
  name: 'dashConcurrency',
  initialState,
  reducers: {
    updateDashConcurrencyDateSelected(state, action: PayloadAction<Date | null>) {
      state.dateSelected = action.payload;
    },
    updateDashConcurrencyCalendarRows(state, action: PayloadAction<Date[][]>) {
      state.calendarRows = action.payload;
    },
    addDashOnlineSearchTab(
      state,
      action: PayloadAction<{
        searchId: string;
        tab: DashConcurrencyOnlineSearch;
      }>,
    ) {
      const { searchId, tab } = action.payload;
      state.online.onlineSearchTabs[searchId] = tab;
      state.online.onlineSearchSelected = searchId;
    },
    selectOnlineSearchTab(
      state,
      action: PayloadAction<{
        searchId: string;
      }>,
    ) {
      const { searchId } = action.payload;
      state.online.onlineSearchSelected = searchId;
    },
    removeOnlineSearchTab(
      state,
      action: PayloadAction<{
        searchId: string;
      }>,
    ) {
      const { searchId } = action.payload;
      delete state.online.onlineSearchTabs[searchId];
      if (Object.keys(state.online.onlineSearchTabs).length > 0) {
        state.online.onlineSearchSelected = Object.keys(state.online.onlineSearchTabs)[0];
      } else {
        state.online.onlineSearchSelected = '';
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashConcurrencyMonthly.pending, (state) => {
        state.monthly = { ...defaultMonthlyData, loading: true };
      })
      .addCase(
        fetchDashConcurrencyMonthly.fulfilled,
        (state, action: PayloadAction<ApiV1DashConcurrencyMonthlyResponse>) => {
          state.monthly.calendarData = action.payload.calendarData;
          state.monthly.tableData = action.payload.tableData;
          state.monthly.loading = false;
        },
      )
      .addCase(fetchDashConcurrencyMonthly.rejected, (state, action) => {
        state.monthly.loading = false;
      })
      .addCase(fetchDashConcurrencyDaily.pending, (state) => {
        state.daily = { ...defaultDailyData, loading: true };
      })
      .addCase(
        fetchDashConcurrencyDaily.fulfilled,
        (state, action: PayloadAction<ChartJSDataset[]>) => {
          state.daily.chartData = action.payload;
          state.daily.loading = false;
        },
      )
      .addCase(fetchDashConcurrencyDaily.rejected, (state, action) => {
        state.daily.loading = false;
      })
      .addCase(fetchDashConcurrencyOnline.pending, (state, action) => {
        const { searchId, concurrentHotelId } = action.meta.arg;
        state.online.onlineSearchTabs[searchId].loadingRecords[concurrentHotelId] = true;
      })
      .addCase(fetchDashConcurrencyOnline.fulfilled, (state, action) => {
        const { searchId, concurrentHotelId } = action.meta.arg;
        state.online.onlineSearchTabs[searchId].loadingRecords[concurrentHotelId] = false;
        state.online.onlineSearchTabs[searchId].data[concurrentHotelId] = action.payload;
      })
      .addCase(fetchDashConcurrencyOnline.rejected, (state, action) => {
        const { searchId, concurrentHotelId } = action.meta.arg;
        state.online.onlineSearchTabs[searchId].loadingRecords[concurrentHotelId] = false;
      });
  },
});

export const fetchDashConcurrencyMonthly = createAsyncThunk<
  ApiV1DashConcurrencyMonthlyResponse,
  void,
  { state: RootState }
>('concurrency/fetchDashConcurrencyMonthly', async (_, { rejectWithValue, getState }) => {
  try {
    const { concurrencyFilters, commonFilters } = getState().filters;
    const { channel, concurrentHotels } = concurrencyFilters;
    const { startDate, endDate } = commonFilters;

    const params = {
      channel,
      concurrentHotels: concurrentHotels.map((x) => x._id),
      startDate: formatDateApi(startDate),
      endDate: formatDateApi(endDate),
    };

    const response = await apiV1ConcurrencyService.get<ApiV1DashConcurrencyMonthlyResponse>(
      `/dashboard/monthly?${stringify(params, { arrayFormat: 'bracket' })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashConcurrencyDaily = createAsyncThunk<
  ApiV1DashConcurrencyDailyResponse,
  void,
  { state: RootState }
>('concurrency/fetchDashConcurrencyDaily', async (_, { rejectWithValue, getState }) => {
  try {
    const { concurrencyFilters } = getState().filters;
    const { channel, dailyFilters, concurrentHotels } = concurrencyFilters;
    const { startDate, endDate, granularity } = dailyFilters;

    const params = {
      channel,
      concurrentHotels: concurrentHotels.map((x) => x._id),
      startDate: formatDateApi(startDate),
      endDate: formatDateApi(endDate),
      granularity,
    };

    const response = await apiV1ConcurrencyService.get<ApiV1DashConcurrencyDailyResponse>(
      `/dashboard/daily-chart?${stringify(params, { arrayFormat: 'bracket' })}`,
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashConcurrencyOnline = createAsyncThunk<
  ApiV1DashConcurrencyOnlineResponse,
  {
    searchId: string;
    concurrentHotelId: string;
  },
  { state: RootState }
>(
  'concurrency/fetchDashConcurrencyOnline',
  async ({ searchId, concurrentHotelId }, { rejectWithValue, getState }) => {
    try {
      const { concurrencyFilters } = getState().filters;
      const { channel, onlineFilters } = concurrencyFilters;
      const { startDate, endDate, adults } = onlineFilters;

      const params = {
        channel,
        startDate: formatDateApi(startDate),
        endDate: formatDateApi(endDate),
        adults,
      };

      const { data } = await apiV1ConcurrencyService.get<ApiV1DashConcurrencyOnlineResponse>(
        `/dashboard/online/${concurrentHotelId}?${stringify(params, { arrayFormat: 'bracket' })}`,
      );

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const {
  updateDashConcurrencyDateSelected,
  updateDashConcurrencyCalendarRows,
  addDashOnlineSearchTab,
  selectOnlineSearchTab,
  removeOnlineSearchTab,
} = dashConcurrencySlice.actions;
export default dashConcurrencySlice.reducer;
