import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from './toast';

const commonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const apiV1AuthService = axios.create({
  baseURL: process.env.REACT_APP_API_V1_AUTH_SERVICE_URL,
  headers: commonHeaders,
});

export const apiV1CrudsService = axios.create({
  baseURL: process.env.REACT_APP_API_V1_CRUDS_SERVICE_URL,
  headers: commonHeaders,
});

export const apiV1DashboardService = axios.create({
  baseURL: process.env.REACT_APP_API_V1_DASHBOARDS_SERVICE_URL,
  headers: commonHeaders,
});

export const apiV1ConcurrencyService = axios.create({
  baseURL: process.env.REACT_APP_API_V1_CONCURRENCY_SERVICE_URL,
  headers: commonHeaders,
});

export const apiV1RmsService = axios.create({
  baseURL: process.env.REACT_APP_API_V1_RMS_SERVICE_URL,
  headers: commonHeaders,
});

function handleResponseSuccess(response: AxiosResponse) {
  try {
    const { headers } = response;
    if (headers['refresh-token'] !== undefined) {
      localStorage.setItem('@token', headers['refresh-token']);
    }
  } catch (error) {
    console.log(`handleResponseSuccess ~ error`, error);
  }
  return response;
}

function handleResponseError(error: AxiosError) {
  try {
    if (error.response) {
      const { data, status } = error.response as any;
      if (+status === 401 && !!localStorage.getItem('@token')) {
        alert('Sessão expirada. Efetue login novamente.');
        localStorage.clear();
        window.location.reload();
      } else if (+status === 500) {
        toast.error('Não foi possível processar sua solicitação no momento.');
      } else {
        if (Array.isArray(data?.message)) {
          data.message.forEach((errStr: string) => toast.error(errStr));
        } else {
          toast.error(data?.message || 'Erro de conexão.');
        }
      }
    } else {
      toast.error('Falha de conexão.');
    }
  } catch (error) {
    console.log('handleResponseError ~ error', error);
  }
  return Promise.reject(error);
}

function handleRequestConfig(config: AxiosRequestConfig) {
  try {
    const token = localStorage.getItem('@token');
    if (token && !!config?.headers) {
      Object.assign(config.headers, { Authorization: `Bearer ${token}` });
    }
  } catch (error) {
    console.log(`handleRequestConfig ~ error`, error);
  }
  return config;
}

apiV1AuthService.interceptors.response.use(handleResponseSuccess, handleResponseError);
apiV1AuthService.interceptors.request.use(handleRequestConfig, Promise.reject);

apiV1CrudsService.interceptors.response.use(handleResponseSuccess, handleResponseError);
apiV1CrudsService.interceptors.request.use(handleRequestConfig, Promise.reject);

apiV1DashboardService.interceptors.response.use(handleResponseSuccess, handleResponseError);
apiV1DashboardService.interceptors.request.use(handleRequestConfig, Promise.reject);

apiV1ConcurrencyService.interceptors.response.use(handleResponseSuccess, handleResponseError);
apiV1ConcurrencyService.interceptors.request.use(handleRequestConfig, Promise.reject);

apiV1RmsService.interceptors.response.use(handleResponseSuccess, handleResponseError);
apiV1RmsService.interceptors.request.use(handleRequestConfig, Promise.reject);
