import { RootState } from '@/redux';
import {
  ApiV1DashEmissionAllResponse,
  ApiV1DashEmissionCityResponse,
  ApiV1DashEmissionDayResponse,
  ApiV1DashEmissionHotelResponse,
  ApiV1DashEmissionMonthResponse,
  ApiV1DashEmissionRegionalResponse,
  ApiV1DashEmissionSummaryResponse,
  ApiV1DashEmissionWeekResponse,
} from '@/types/apiv1/responses/dashboards/api-v1-emission-responses';
import { ChartJSDataset } from '@/types/dashboards/charts/chart';
import { DashCommonFilters, DashEmissionFilters } from '@/types/dashboards/dash-filters';
import {
  DashEmissionCityData,
  DashEmissionDayData,
  DashEmissionHotelData,
  DashEmissionMonthData,
  DashEmissionRegionalData,
  DashEmissionType,
  DashEmissionWeekData,
  DashEmissionWeekDataGroupedByBookingMonth,
} from '@/types/dashboards/emission';
import { WidgetSummaryCardData } from '@/types/dashboards/widgets/widget-summary';
import { EmissionData } from '@/types/models/emission-goal';
import { apiV1DashboardService } from '@/utils/api';
import { formatDateApi } from '@/utils/dates';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { startOfMonth } from 'date-fns';
import { stringify } from 'query-string';

interface DashEmissionSliceState {
  summary: {
    loading: boolean;
    revenue: number;
    goal: number;
    goalPercentage: number;
    goalAchieved: number;
    widgetCard1: WidgetSummaryCardData;
    widgetCard2: WidgetSummaryCardData;
  };
  allEmissions: {
    loading: boolean;
    data: EmissionData[];
  };
  month: {
    loading: boolean;
    data: DashEmissionMonthData[];
    chartData: ChartJSDataset[];
  };
  week: {
    loading: boolean;
    data: DashEmissionWeekData[];
    dataGroupedByBookingMonth: DashEmissionWeekDataGroupedByBookingMonth[];
    chartData: ChartJSDataset[];
  };
  day: {
    loading: boolean;
    data: DashEmissionDayData[];
    chartData: ChartJSDataset[];
  };
  regionals: {
    loading: boolean;
    data: DashEmissionRegionalData[];
    chartData: ChartJSDataset[];
  };
  hotels: {
    loading: boolean;
    data: DashEmissionHotelData[];
    chartData: ChartJSDataset[];
  };
  cities: {
    loading: boolean;
    data: DashEmissionCityData[];
    chartData: ChartJSDataset[];
  };
  editingShare: {
    emissionGoalId: string | null;
  };
}

const summaryDefaultValues: ApiV1DashEmissionSummaryResponse = {
  revenue: 0,
  goal: 0,
  goalPercentage: 0,
  goalAchieved: 0,
  widgetCard1: {
    id: '',
    slug: '',
    loading: false,
    titleTop: '',
    titleCenter: '',
    iconTopRight: '',
  },
  widgetCard2: {
    id: '',
    slug: '',
    loading: false,
    titleTop: '',
    titleCenter: '',
    iconTopRight: '',
  },
};

const defaulData = {
  loading: false,
  data: [],
  chartData: [],
};

const defaulDataWithBookingMonth = { ...defaulData, dataGroupedByBookingMonth: [] };

const initialState: DashEmissionSliceState = {
  summary: {
    loading: false,
    ...summaryDefaultValues,
  },
  allEmissions: {
    loading: false,
    data: [],
  },
  month: defaulData,
  week: defaulDataWithBookingMonth,
  day: defaulData,
  regionals: defaulData,
  hotels: defaulData,
  cities: defaulData,
  editingShare: {
    emissionGoalId: null,
  },
};

const dashEmissionSlice = createSlice({
  name: 'dashEmission',
  initialState,
  reducers: {
    setEditingEmissionCustomShare(
      state,
      action: PayloadAction<DashEmissionSliceState['editingShare']>,
    ) {
      state.editingShare = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashEmissionSummary.pending, (state) => {
        state.summary = {
          ...summaryDefaultValues,
          loading: false,
        };
      })
      .addCase(
        fetchDashEmissionSummary.fulfilled,
        (state, action: PayloadAction<ApiV1DashEmissionSummaryResponse>) => {
          state.summary = {
            ...action.payload,
            loading: false,
          };
        },
      )
      .addCase(fetchDashEmissionSummary.rejected, (state, action) => {
        state.summary = {
          ...summaryDefaultValues,
          loading: false,
        };
      })
      .addCase(fetchDashEmissionAllEmissions.pending, (state) => {
        state.allEmissions = {
          data: [],
          loading: false,
        };
      })
      .addCase(
        fetchDashEmissionAllEmissions.fulfilled,
        (state, action: PayloadAction<ApiV1DashEmissionAllResponse>) => {
          state.allEmissions = {
            data: action.payload,
            loading: false,
          };
        },
      )
      .addCase(fetchDashEmissionAllEmissions.rejected, (state, action) => {
        state.allEmissions = {
          data: [],
          loading: false,
        };
      })
      .addCase(fetchDashEmissionMonth.pending, (state) => {
        state.month = { ...defaulData, loading: true };
      })
      .addCase(
        fetchDashEmissionMonth.fulfilled,
        (state, action: PayloadAction<ApiV1DashEmissionMonthResponse>) => {
          state.month = {
            loading: false,
            ...action.payload,
          };
        },
      )
      .addCase(fetchDashEmissionMonth.rejected, (state, action) => {
        state.month = {
          ...defaulData,
          loading: false,
        };
      })
      .addCase(fetchDashEmissionWeek.pending, (state) => {
        state.week = { ...defaulDataWithBookingMonth, loading: true };
      })
      .addCase(
        fetchDashEmissionWeek.fulfilled,
        (state, action: PayloadAction<ApiV1DashEmissionWeekResponse>) => {
          state.week = {
            loading: false,
            ...action.payload,
          };
        },
      )
      .addCase(fetchDashEmissionWeek.rejected, (state, action) => {
        state.week = {
          ...defaulDataWithBookingMonth,
          loading: false,
        };
      })
      .addCase(fetchDashEmissionDay.pending, (state) => {
        state.day = { ...defaulData, loading: true };
      })
      .addCase(
        fetchDashEmissionDay.fulfilled,
        (state, action: PayloadAction<ApiV1DashEmissionDayResponse>) => {
          state.day = {
            loading: false,
            ...action.payload,
          };
        },
      )
      .addCase(fetchDashEmissionDay.rejected, (state, action) => {
        state.day = {
          ...defaulData,
          loading: false,
        };
      })
      .addCase(fetchDashEmissionRegionals.pending, (state) => {
        state.regionals = { ...defaulData, loading: true };
      })
      .addCase(
        fetchDashEmissionRegionals.fulfilled,
        (state, action: PayloadAction<ApiV1DashEmissionRegionalResponse>) => {
          state.regionals = {
            loading: false,
            ...action.payload,
          };
        },
      )
      .addCase(fetchDashEmissionRegionals.rejected, (state, action) => {
        state.regionals = {
          ...defaulData,
          loading: false,
        };
      })
      .addCase(fetchDashEmissionHotels.pending, (state) => {
        state.hotels = { ...defaulData, loading: true };
      })
      .addCase(
        fetchDashEmissionHotels.fulfilled,
        (state, action: PayloadAction<ApiV1DashEmissionHotelResponse>) => {
          state.hotels = {
            loading: false,
            ...action.payload,
          };
        },
      )
      .addCase(fetchDashEmissionHotels.rejected, (state, action) => {
        state.hotels = {
          ...defaulData,
          loading: false,
        };
      })
      .addCase(fetchDashEmissionCities.pending, (state) => {
        state.cities = { ...defaulData, loading: true };
      })
      .addCase(
        fetchDashEmissionCities.fulfilled,
        (state, action: PayloadAction<ApiV1DashEmissionCityResponse>) => {
          state.cities = {
            loading: false,
            ...action.payload,
          };
        },
      )
      .addCase(fetchDashEmissionCities.rejected, (state, action) => {
        state.cities = {
          ...defaulData,
          loading: false,
        };
      });
  },
});

export const fetchDashEmissionSummary = createAsyncThunk<
  ApiV1DashEmissionSummaryResponse,
  { type: DashEmissionType },
  { state: RootState }
>('emissions/fetchDashEmissionSummary', async ({ type }, { rejectWithValue, getState }) => {
  try {
    const params = mountEmissionRequest(
      type,
      getState().filters.emissionFilters,
      getState().filters.commonFilters,
    );
    validateParams(type, params);
    const url = `/emissions-${type}/summary?${stringify(params, { arrayFormat: 'bracket' })}`;
    const response = await apiV1DashboardService.get<ApiV1DashEmissionSummaryResponse>(url);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashEmissionAllEmissions = createAsyncThunk<
  EmissionData[],
  { type: DashEmissionType },
  { state: RootState }
>('emissions/fetchDashEmissionAllEmissions', async ({ type }, { rejectWithValue, getState }) => {
  try {
    const params = mountEmissionRequest(
      type,
      getState().filters.emissionFilters,
      getState().filters.commonFilters,
    );
    validateParams(type, params);
    const url = `/emissions-${type}/all?${stringify(params, { arrayFormat: 'bracket' })}`;
    const response = await apiV1DashboardService.get<ApiV1DashEmissionAllResponse>(url);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashEmissionMonth = createAsyncThunk<
  ApiV1DashEmissionMonthResponse,
  { type: DashEmissionType },
  { state: RootState }
>('emissions/fetchDashEmissionMonth', async ({ type }, { rejectWithValue, getState }) => {
  try {
    const params = mountEmissionRequest(
      type,
      getState().filters.emissionFilters,
      getState().filters.commonFilters,
    );
    validateParams(type, params);
    const url = `/emissions-${type}/month?${stringify(params, { arrayFormat: 'bracket' })}`;
    const response = await apiV1DashboardService.get<ApiV1DashEmissionMonthResponse>(url);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashEmissionWeek = createAsyncThunk<
  ApiV1DashEmissionWeekResponse,
  { type: DashEmissionType },
  { state: RootState }
>('emissions/fetchDashEmissionWeek', async ({ type }, { rejectWithValue, getState }) => {
  try {
    const params = mountEmissionRequest(
      type,
      getState().filters.emissionFilters,
      getState().filters.commonFilters,
    );
    validateParams(type, params);
    const url = `/emissions-${type}/week?${stringify(params, { arrayFormat: 'bracket' })}`;
    const response = await apiV1DashboardService.get<ApiV1DashEmissionWeekResponse>(url);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashEmissionDay = createAsyncThunk<
  ApiV1DashEmissionDayResponse,
  { type: DashEmissionType },
  { state: RootState }
>('emissions/fetchDashEmissionDay', async ({ type }, { rejectWithValue, getState }) => {
  try {
    const params = mountEmissionRequest(
      type,
      getState().filters.emissionFilters,
      getState().filters.commonFilters,
    );
    validateParams(type, params);
    const url = `/emissions-${type}/day?${stringify(params, { arrayFormat: 'bracket' })}`;
    const response = await apiV1DashboardService.get<ApiV1DashEmissionDayResponse>(url);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashEmissionRegionals = createAsyncThunk<
  ApiV1DashEmissionRegionalResponse,
  { type: DashEmissionType },
  { state: RootState }
>('emissions/fetchDashEmissionRegionals', async ({ type }, { rejectWithValue, getState }) => {
  try {
    const params = mountEmissionRequest(
      type,
      getState().filters.emissionFilters,
      getState().filters.commonFilters,
    );
    validateParams(type, params);
    const url = `/emissions-${type}/regionals?${stringify(params, { arrayFormat: 'bracket' })}`;
    const response = await apiV1DashboardService.get<ApiV1DashEmissionRegionalResponse>(url);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashEmissionHotels = createAsyncThunk<
  ApiV1DashEmissionHotelResponse,
  { type: DashEmissionType },
  { state: RootState }
>('emissions/fetchDashEmissionHotels', async ({ type }, { rejectWithValue, getState }) => {
  try {
    const params = mountEmissionRequest(
      type,
      getState().filters.emissionFilters,
      getState().filters.commonFilters,
    );
    validateParams(type, params);
    const url = `/emissions-${type}/hotels?${stringify(params, { arrayFormat: 'bracket' })}`;
    const response = await apiV1DashboardService.get<ApiV1DashEmissionHotelResponse>(url);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchDashEmissionCities = createAsyncThunk<
  ApiV1DashEmissionCityResponse,
  { type: DashEmissionType },
  { state: RootState }
>('emissions/fetchDashEmissionCities', async ({ type }, { rejectWithValue, getState }) => {
  try {
    const params = mountEmissionRequest(
      type,
      getState().filters.emissionFilters,
      getState().filters.commonFilters,
    );
    validateParams(type, params);
    const url = `/emissions-${type}/cities?${stringify(params, { arrayFormat: 'bracket' })}`;
    const response = await apiV1DashboardService.get<ApiV1DashEmissionCityResponse>(url);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const { setEditingEmissionCustomShare } = dashEmissionSlice.actions;
export default dashEmissionSlice.reducer;

function mountEmissionRequest(
  type: DashEmissionType,
  filters: DashEmissionFilters,
  commonFilters: DashCommonFilters,
) {
  const { emissionMonth, bookingPeriods } = filters;
  const { segments, channels } = commonFilters;
  const params: any = {
    emissionMonth: formatDateApi(startOfMonth(emissionMonth)),
    bookingPeriods: bookingPeriods.map((date) => formatDateApi(startOfMonth(date))),
  };

  if (type === DashEmissionType.SEGMENTS) {
    params.segments = segments.map((x) => x._id);
  }

  if (type === DashEmissionType.CHANNELS) {
    params.channels = channels.map((channel) => channel._id);
  }

  return params;
}
function validateParams(type: DashEmissionType, params: any) {
  if (type === DashEmissionType.SEGMENTS && params.segments?.length === 0) {
    throw new Error('No segments selected');
  } else if (type === DashEmissionType.CHANNELS && params.channels?.length === 0) {
    throw new Error('No channels selected');
  }
}
