import { UserSessionMode } from '@/types/enums/user-session-mode.enum';
import { UserSession, UserSessionDropdowns } from '@/types/models/user-session';
import { sortDropDowns } from './array';

export function sanitizeUserSessionDropdowns(
  dropDownsOptions: UserSessionDropdowns,
  userSession: UserSession
): UserSessionDropdowns {
  const { mode } = userSession;
  const { hotels, regionals, cities, wallets } = dropDownsOptions;
  switch (mode) {
    case UserSessionMode.HOTEL:
      return {
        hotels: sortDropDowns(hotels, userSession.hotels),
        regionals,
        cities,
        wallets,
      };
    case UserSessionMode.REGIONAL:
      return {
        hotels,
        regionals: sortDropDowns(regionals, userSession.regionals),
        cities,
        wallets,
      };
    case UserSessionMode.CITY:
      return {
        hotels,
        regionals,
        cities: sortDropDowns(cities, userSession.cities),
        wallets,
      };
    case UserSessionMode.WALLET:
      return {
        hotels,
        regionals,
        cities,
        wallets: sortDropDowns(wallets, userSession.wallets),
      };
    default:
      return {
        hotels,
        regionals,
        cities,
        wallets,
      };
  }
}
