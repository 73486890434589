import { Fluctuation } from '@/types/models/fluctuation';
import { formatDate } from '@/utils/dates';
import { formatCurrency, formatPercentage } from '@/utils/number';
import { Grid, TextField } from '@mui/material';

interface Props {
  fluctuation: Fluctuation;
}

export default function FluctuationIndexes({ fluctuation }: Props) {
  return (
    <Grid container spacing={3} sx={{ mb: 2 }}>
      <Grid item xs={12} md={2}>
        <TextField disabled fullWidth value={fluctuation.bar?.rule?.name} label="Nome da regra" />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField disabled fullWidth value={fluctuation.ratePlan.name} label="Plano tarifário" />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          disabled
          fullWidth
          value={!!fluctuation.appliedPricesAt ? formatDate(fluctuation.appliedPricesAt) : 'N/A'}
          label="Última integração"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          disabled
          fullWidth
          value={formatPercentage(fluctuation.indexes.occupation)}
          label="Ocupação"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          disabled
          fullWidth
          value={formatCurrency(fluctuation.indexes.revenue)}
          label="Receita do dia"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField disabled fullWidth value={fluctuation.indexes.roomNight} label="RNs" />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          disabled
          fullWidth
          value={fluctuation.indexes.reservations}
          label="Qtde. Reservas"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          disabled
          fullWidth
          value={formatCurrency(fluctuation.indexes.revPar)}
          label="RevPar"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          disabled
          fullWidth
          value={formatCurrency(fluctuation.indexes.dailyAverage)}
          label="Diária Média"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField disabled fullWidth value={fluctuation.indexes.los} label="LOS" />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField disabled fullWidth value={fluctuation.indexes.pax} label="PAX" />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField disabled fullWidth value={fluctuation.indexes.paxChd} label="PAX (crianças)" />
      </Grid>
    </Grid>
  );
}
