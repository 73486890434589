import { GenericIdName } from '@/types/models/generic-id-name';

export enum DashPerspective {
  SUM = 'SUM',
  INDIVIDUAL = 'INDIVIDUAL',
}

export function getDashPerspectiveHumanRead(value: DashPerspective) {
  switch (value) {
    case DashPerspective.SUM:
      return 'Soma';
    case DashPerspective.INDIVIDUAL:
      return 'Individual';
    default:
      return value;
  }
}

export const allDashPerspective: GenericIdName[] = Object.values(DashPerspective).map((x) => ({
  _id: x,
  name: getDashPerspectiveHumanRead(x),
}));
